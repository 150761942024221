$comment-hierarchy-line-color : $primary-color !default;

html body .comments-wrapper > .items > article[class^="comment"], html #page-content-wrapper .comments-wrapper > .items > article[class^="comment"] {
  margin-bottom: 20px;
}

.comment-replies {
  margin-top: -20px !important;
  padding-top: 20px;
  overflow: hidden;

  article[class^="comment"] {
    margin: 0 -15px 15px 45px;

    .comment-header:before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 50%;
      left: -30px;
      width: 28px;//100%;
      height: 100vh;
      border-left: 3px $comment-hierarchy-line-color solid;
      border-bottom: 3px $comment-hierarchy-line-color solid;
      //border-radius: 7px;
      border-bottom-left-radius: 7px;
    }
    .media-body {
      z-index: 1;
    }

  }
}
.comments-wrapper .comment-wrapper {
  article[class^="comment"] {
    .row.comment-body.panel-body {

      .comment-main-wrapper {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        margin-left: 10px;
        align-items: center;
        margin-top: 3px;

        .comment-content-container {

          p.comment-text {
            margin:0;
          }
        }
      }

      .reply-button.pull-right {
        color: $comment-hierarchy-line-color;
      }
    }
  }
}