@import "_global_includes.scss";

/* PRINT */
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  a:after {
    content: none !important;
  }
  .collapse {
    display: block !important;
    height: auto !important;
  }
  #wrapper {
    background-image: none !important;
    background-color: white !important;
    margin: 0;
    padding: 0;
  }
  #page-content-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
    > div,
    .content-dynamic {
      margin: 0;
      padding: 0;
    }
  }
  .accordion-count, .accordion-title {
    font-size: 18px !important;
    padding: 7px !important;
    width: auto !important;
    height: auto !important;
    color: $theme-white !important;
  }
  .content-title {
    background-color: #ffffff;
    .widget-headline-icon {
      display: none;
    }
    .breadcrumbs {
      padding-left: 0;
      padding-right: 0;
      ol {
        font-size: 12pt;
        background-color: #ffffff;
        li {
          //display: inline-block;
          span {
            &:before {
              content: '/';
              font-size: 12pt;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .hidden-print,
  #sidebar-right,
  #sidebar-left,
  #comment-section,
  input,
  label,
  .dt-buttons.btn-group,
  .dataTables_length,
  .modal,
  .noprint,
  div.alert,
  header,
  .pagination,
  .group-media,
  .btn,
  .footer,
  form,
  .pager,
  #comments,
  .dataTables_info,
  .nav,
  ul.links.list-inline,
  ul.action-links,
    //hide seetings
  .dropdown-toggle.hidden-print + ul.dropdown-menu {
    display: none !important;
  }
  table, figure {
    page-break-inside: avoid;
  }

}