@import "../_global_includes.scss";
@import "../../../../../widgets/comment/assets/scss/comment_hierarchy_lines";

.panel-footer {
  border: none;
  background-color: transparent;

  hr {
    border-width: 3px;
  }

  .dividing-footer-background {
    height: 40px;
    width: 100%;
    display: block;
    margin: 15px 0;
  }

  .footer-news-navigator {
    display: flex;
    align-items: center;

    > form {
      flex: 1;
    }

    .news-nav-buttons {
      display: flex;
      justify-content: space-around;
    }

    .nav-buttons {
      padding: 0 15px;
      font-weight: 600;

      a, i {
        color: $body-font-color;
        font-weight: 600;
      }
    }
  }

  .footer-text-informations {
    display: flex;
    justify-content: space-between;

    .footer-information-right {
      text-align: end;
    }
  }

  .feedback-container.feedback-inline {
    #feedback-panel {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      .form-group {
        width: 100%;
      }
    }

    .btn {
      background: $primary-color;
      color: $theme-white;
      border-radius: 7px;
    }
  }

  .footer-blog-toggle-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;

    .footer-blog-toggle, i {
      cursor: pointer;
      color: $body-font-color;
      font-weight: 600;
      font-size: 16px;
    }
  }


  .footer-blogs-container {
    .blogs-wrapper .items .panel .row {
      margin: 0;
    }

    .list-view {
      padding: 0;

      article {
        border: 3px $primary-color solid;
        border-radius: 7px;

        .blog-title {
          margin-top: 0;
        }

        .blog-header.panel-heading {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 12px 15px;
          border-radius: 7px 7px 0 0;

          .media-body {
            display: flex;
            justify-content: space-between;
            padding-left: 15px;

            a {
              color: $body-font-color;
            }

            .blog-head-right {
              display: flex;
              align-items: center;
              gap: 5px;

              .text-muted {
                color: $body-font-color;
                font-size: 16px;
              }
            }
          }
        }
      }

      .blog-body.panel-footer.clearfix {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .delete-blog, .edit-blog {
          padding: 7px 11px;
          border-radius: 7px;
        }
      }
    }

    h4, h3 {
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
      color: $body-font-color;
    }
  }

  .footer-comments-container {
    .comments-wrapper {
      .row {
        margin: 0;
      }
    }

    .comment-header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .comment-header-title {
        display: flex;
        margin: 0;
        height: 40px;
        align-items: baseline;
      }

      .order_comments {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          white-space: nowrap;
          margin: 0;
        }

        select {
          border-radius: 7px;
          height: 40px;
        }

        .filter-icon {
          background: $primary-color;
          padding: 8px 11px;
          border-radius: 7px;
          color: $theme-white;
        }
      }
    }

    .list-view {
      padding: 0;

      article {
        border: 3px $primary-color solid;
        border-radius: 7px;

        .user-image {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        .comment-title {
          margin-top: 0;
        }

        .comment-header.panel-heading {
          height: 40px;
          display: flex;
          align-items: center;
          padding: 12px 15px;
          border-radius: 7px 7px 0 0;

          .media-body {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            padding-left: 15px;

            .comment-head-right {
              display: flex;
              align-items: center;
              gap: 5px;

              .edit-comment, .delete-comment, .reply-comment {
                color: $body-font-color;
                font-size: 16px;
                background: transparent;
              }

              .text-muted {
                color: $body-font-color;
                font-size: 16px;
              }
            }
          }
        }
      }
      .row.comment-body.panel-body {
        font-size: 16px;
      }

      .comment-body.panel-footer.clearfix {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        font-size: 16px;

        .delete-comment, .edit-comment, .reply-comment{
          padding: 7px 11px;
          border-radius: 7px;
        }
      }
    }

    #comment-add-form div.ck-editor.comment-body, .modal-body #cke_comments_editor {
      padding: 10px 15px;
      border: none;
      border-radius: 10px;

      #cke_ObjectCommentModel_text {
        border: none;
        box-shadow: 0 0 6px $theme-white;
      }

      .cke_inner.cke_reset {
        border-bottom: 1px solid $theme-white;
      }

      #cke_1_bottom, #cke_2_bottom {
        display: none;
      }
    }

    h4, h3 {
      padding: 0;
      margin: 0;
      margin-bottom: 20px;
      color: $body-font-color;
    }

    #comment-add-form {
      div.comment-form-wrapper {
        display: flex;
        gap: 40px;
        margin-bottom: 20px;

        div.comment-body.border-radius-10 {
          margin-top: 5px;
          padding: 20px;
          box-shadow: 0px 0px 6px #00000029;
          width: 60%;
        }

        div.help-text {
          width: 25%;

          p {
            margin-top: 0;
          }
        }

        div.user-image {
          padding-top: 0;
          border-radius: 50%;
        }
      }
    }

    .send-button {
      height: 40px;
      background: $primary-color;
      border-radius: 7px;
      color: $theme-white;
    }

    .send-wrapper {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      right: 3px;
      bottom: 3px;

      &:before {
        content: "\f1d8";
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        color: $theme-white;
        pointer-events: none;
        z-index: 2;
      }

      .send-button {
        float: unset;
        width: 40px;
      }
    }

    #comment-add-form {
      position: relative;

      textarea {
        resize: none;
        border-radius: 7px;
        border-color: $body-font-color;
        min-height: 100px;
      }

      input {
        resize: none;
        border-radius: 7px;
        border-color: $body-font-color;
      }
    }
  }

  .footer-buttons-row-container {
    display: flex;
    align-items: center;
    gap: 5px;

    .footer-comment-toggle-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      align-items: center;

      .footer-comment-toggle, i {
        cursor: pointer;
        color: $body-font-color;
        font-size: 16px;
        font-weight: 600;
      }
    }

    #total_like_refresh {
      color: $theme-white;
      padding-right: 11px;
    }

    .footer-button-container {
      background: $primary-color;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        color: $theme-white;
        padding: 8px 11px;
      }
    }
  }

  #footer_star_rating_ajax {
    display: flex;
    align-items: center;
  }

  .footer-rating-container {
    display: flex;
    gap: 5px;

    .star-rating-control {
      display: inline-block;
    }

    .star-rating-control div.star-rating,
    .star-rating-control div.star-rating a {
      background: url('/images/theme/star.gif') no-repeat 0 0; // Hearts
    }

    .star-rating-control div.star-rating-hover a {
      background-position: 0 -32px;
    }
  }
}

.star-rating-on, .star-rating-hover {
  filter: brightness(0) saturate(100%) invert(89%) sepia(56%) saturate(6813%) hue-rotate(19deg) brightness(89%) contrast(82%);
}