@import "../_global_includes.scss";

/* ------------ Level & Badges ------------ */

#eLearning_level_widget, #levelsystem_badges_widget {
  .widget-content-wrapper {
    border: none;
  }

  .preview_image {
    margin-top: 2px;
  }
}

.badge-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 15px;
  background: transparent;
}

#levelsystem_badges_widget {
  .badge-content {
    justify-content: space-around;
  }
}

.badge-frontend-icon {
  font-size: 75px;
  margin: 12.5px;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary-color;
}

.badge-frontend-image {
  width: 100px;
  height: 100px;
  border-radius: 4px;
}

.badge-list-item {
  position: relative;
  margin: 7.5px;

  &.grayed_out img,
  &.grayed_out i {
    //filter: contrast(0.1);
    filter: opacity(0.2);
  }
}

.badge-counter {
  position: absolute;
  top: 5px;
  right: 5px;
  background: $theme-blue;
  min-width: 22px;
  min-height: 22px;
  border-radius: 11px;
  color: $theme-white;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 1px 2px $theme-white;
}

.badge-name {
  max-width: 100px;
  display: flex;
  justify-content: center;
  color: $primary-color;
  position: relative;
  top: 5px;
  text-align: center;
}

.badge-overview-counter {
  font-weight: 600;
  color: $theme-black;
  margin: 15px;
}

.badge-overview-title {
  font-weight: 600;
  color: $theme-black;
  font-size: 14pt;
  margin: 15px;
}

#levelsystem-content-wrapper {
  overflow: visible !important;
}

.user-profile-overview {
  min-height: 500px;
}

.badge-profile-box {
  border: 1px solid $theme-green;
  border-radius: 32px;
}

.badge-profile-title {
  margin: 15px 0;
  color: $theme-black;
  font-size: 20px;
  font-weight: 600;
}

body.profile #page-content-wrapper,
body.profile #page-content-wrapper .container-fluid {
  overflow: visible !important;
}

.profile-wrapper {
  font-size: 20px;
  color: $theme-black;
  font-weight: 600;
}

.profile-content-wrapper {
  padding: 15px 45px;
}

.no-badge-content {
  font-weight: 600;
  color: $primary-color;
}

#levelsystem_badges_widget .front-page-widget .widget-headline-title h3,
#eLearning_level_widget .front-page-widget .widget-headline-title h3 {
  background: $theme-green;
  color: $front-widget-head-background-color;
}

.levelsystem-heading .content-title {
  padding-bottom: 3px;
}

#levelsystem_badges_widget .front-page-widget .widget-headline-title i,
.levelsystem-heading .content-title > i,
.levelsystem-widget-wrapper #eLearning_level_widget .front-page-widget .widget-headline-title i {
  font-size: 15pt !important;
  width: 1.8em !important;
  padding: 14px 40px 0 0;
  float: left !important;
  position: absolute;
  left: 10px;
  top: -5px;
  margin: 0;
  background: transparent;
  color: $front-widget-head-background-color;
}

#levelsystem_badges_widget .front-page-widget .widget-headline-title i,
.levelsystem-widget-wrapper #eLearning_level_widget .front-page-widget .widget-headline-title i {
  left: 30px;
  top: -2px;
}

//Badges Overview End

.inner-event-list {
  display: inline-block;
}

.event-point-list {
  counter-reset: item;
  margin-left: 0;
  padding-left: 0;
  margin-top: 5px;
}

.event-point-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: .5em;
  margin-left: 2em;
}

.event-point-list li::before {
  display: inline-block;
  content: counter(item) ") ";
  counter-increment: item;
  width: 2em;
  margin-left: -2em;
  padding-right: 10px;
}

//MyLevelWidget start
#eLearning_level_widget {
  color: $theme-black;

  .widget-content-wrapper {
    .row {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .nr-level {
    h1 {
      font-weight: 700;
      font-size: 50px;
      margin-top: 16px;
    }
  }

  .level-info {
    padding: 20px 0 0 30px;

    .level-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
    }

    .level-description {
      font-size: 16px;
    }
  }

  p {
    color: $theme-black;
  }

  #points-uncounted {
    color: white;
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
  }

  .progress-level {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: $color-progress-level;
    height: 10px;
    position: relative;
    overflow: visible;
    width: 50%;
    margin: 0;

    .progress-bar {
      background: $color-progress-bar;
      box-shadow: unset;
    }
  }

  .badge-current-level {
    padding: 7px 10px;
    font-size: 15px;
    //border-radius: 50%;
    margin-top: -6px;
    background: $theme-blue;
    color: $theme-white;
    min-width: 110px;
    width: 25%;
    //border: 2px solid $theme-white;

    &.image-icon-level-display {
      padding: 0;
      overflow: hidden;
      background: transparent;
      margin-top: -10px;
      z-index: 10;
      border-radius: 0;

      i {
        color: $theme-black;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
      }
    }
  }

  .badge-next-level {
    padding: 7px 10px;
    font-size: 15px;
    margin-top: -6px;
    background: #f2f2f2;
    color: $primary-color;
    min-width: 110px;
    width: 25%;

    &.image-icon-level-display {
      padding: 0;
      overflow: hidden;
      background: transparent;
      margin-top: -10px;
      z-index: 10;
      border-radius: 0;

      i {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
      }
    }

    i {
      color: $theme-blue;
    }
  }

  .experience-points {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px;
  }

  .level-report {
    margin-bottom: 0px;
    color: grey;

    .complete-level {
      font-weight: 600;
    }
  }
}

//MyLevelWidget at the profile
.level-wrapper {
  .my-levels-profile-title {
    margin: 15px 0;
    color: $theme-black;
    font-size: 20px;
    font-weight: 600;
  }

  #eLearning_level_widget {
    border: 1px solid $theme-red;
    border-radius: 32px;
    padding: 0px 15px;
    margin-bottom: 35px;

    .badge-current-level {
      left: 10px;

    }

    .badge-next-level {
      right: 10px;

    }

    .widget-content-wrapper {
      border: none;
    }

    .front-page-widget {
      box-shadow: none;
    }
  }
}

//MyLevelWidget end
//Levels Overview Start
.content-levels {
  padding-left: 0px;
}

.content-rules {
  padding-right: 0px;
}

#level-list {

  #level-carousel {
    width: 100%; // brauche ich evtl nicht
    .carousel-inner {
      display: flex;
      align-items: stretch;
      padding: 0 30px;

      .item.active,
      .item.next,
      .item.prev {
        display: flex;
        height: 100%;
        margin-top: 5px;
      }
    }
  }

  .level-item {
    padding: 10px;
    background: white;

    .level-image {
      display: flex;
      align-items: start;
      justify-content: center;

      img {
        //max-width: 30px;
        max-height: 100px;
        object-fit: scale-down;
        margin-bottom: 5px;
        margin-top: -5px;
      }
    }

    .level-icon {
      display: flex;
      align-items: center;
      font-size: 30px;
      justify-content: center;
      min-width: 30px;
      max-width: 30px;
    }

    .level-position {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      margin-top: -10px;
    }

    .level-info {
      display: flex;
    }

    .level-content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .level-item.level-achieved {
    background: #ffffff;
  }
}

.empty-user-points {
  font-weight: 600;
  color: $primary-color;
}

//Levels Overview End

//WUserLevelPoints Start
.levelsystem-profile-wrapper {
  .level-history-wrapper {
    .level-profile-title {
      margin: 15px 0;
      color: $theme-black;
      font-size: 20px;
      margin-top: 35px;
      font-weight: 600;
    }

    .level-profile-box {
      border: 1px solid $theme-red;
      border-radius: 32px;
      padding: 30px;

      #user-points-overview {
        table {
          margin-bottom: 0;

          thead {
            border-bottom: 1px solid $theme-white;

            tr:first-child {
              border: none;
            }

            th {
              background: $theme-red;
              color: white;
              border: 1px solid $theme-white;
            }
          }

          tr {
            border-bottom: 1px solid $theme-white;

            td {
              border: 1px solid $theme-white;
            }
          }

          tbody {
            td {
              background: #f2f2f2;
              color: $theme-black;
            }
          }
        }
      }
    }
  }
}

//WUserLevelPoints End
.levelsystem-widget-wrapper {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
}

#eLearning_level_widget,
#levelsystem_badges_widget {
  margin-bottom: 30px;
  width: 100% !important;

  .front-page-widget-wrapper {
    height: 100%;
    background: transparent;

    .front-page-widget {
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }
}

@media (max-width: $screen-md-max) {
  .nr-level, .level-info {
    width: 100%;
    text-align: center;

    h1 {
      margin-bottom: 0;
    }
  }
  .level-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.row.level-wrapper {
  margin: 0 15px !important;
}

.nr-level, .level-info {
  display: inline-block;
}

.level-info-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  justify-content: flex-end;
}

.counter-with-points-container {
  width: max-content;
  position: absolute;
  transform: translateY(-80%);
  z-index: 20;
}

span.experience-points-to-next-level {
  color: grey;
}


.counter-with-points-container {
  width: max-content;
  position: absolute;
  transform: translateY(-80%);
  z-index: 20;
}

span.experience-points-to-next-level {
  color: grey;
}


.points-container {
  min-height: 60px;
  display: flex;
  align-items: flex-end;
  padding: 0 10px;
}

.levelsystem-grid-container {
  background-color: #f0f0f0;
  display: inline-flex;
  width: 100%;
}

.levelsystem-left-column {
  width: 60%;
  display: inline-block;
  margin-right: 20px;
}

.levelsystem-right-column {
  width: 40%;
  display: inline-block;
}

.levelsystem-overview-header, .levelsystem-intro, .levelsystem-levels, .levelsystem-description {
  margin-bottom: 20px;
}

.levelsystem-overview-area {
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
}

.levelsystem-levels {
  grid-area: slider;
  padding: 0;

  .icon-prev, .icon-next {
    color: $color-progress-bar;
    font-size: 85px;
    top: 25%;
    text-shadow: none;
  }

}

.levelsystem-greeting {
  height: fit-content;
  display: flex;
  justify-content: space-between;
}

body.action-overview .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}

.levelsystem-heading.panel-heading.row {
  margin: 0;
  padding: 0 15px;
}

.levelsystem-heading-row {

  height: fit-content;

  .breadcrumbs {
    padding-left: 20px;

    .breadcrumb {
      padding: 0;
    }
  }

  .fa.fa-award {
    display: none;
  }
}

.levelsystem-header-image {
  width: 100%;
}

@media (max-width: $screen-lg) {
  .levelsystem-overview-area #level-list {
    .level-item {
      flex-direction: column;

      .level-position-wrapper{
        display: flex;
        align-items: center;
      }

      .level-short-info,  {
        flex-direction: row;
        width: unset;
      }

      .level-long-info{
        width: unset;
      }

      .level-image {

      }
    }
  }
}

#level-list {
  .level-short-info {
    width: 30%;
    display: flex;
    flex-direction: column;
  }

  .level-long-info {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .level-item {
    //opacity: 0; // reinmachen, wenn es mit mehreren items nicht klappt
  }

  .level-item {
    //opacity: 1; // reinmachen, wenn es mit mehreren items nicht klappt
    display: flex;
    gap: 10px;

    .level-name {
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 10px;
    }

    .level-content {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .level-points {
      font-weight: 700;
      font-size: 18px;
      margin-left: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.carousel-control.right,
.carousel-control.left {
  background-image: none;
  width: 5%;
  opacity: 100%;
}

@media only screen and (min-width: 760px) and (max-width: 1600px) {
  #eLearning_level_widget .badge-current-level,
  #eLearning_level_widget .badge-next-level {
    width: 30%;
  }
}

.carousel-control:hover {
  span::before {
    opacity: .6;
  }
}

.controller-level.action-overview #page-content-wrapper > .container-fluid {
  background: none !important;
}

.levelsystem-heading {
  .widget-headline-icon {
    display: none;
  }
}

.breadcrumbs {
  padding-left: 0 !important;
}

#level-list {
  .carousel-inner .item.active.left {
    transform: translate3d(-33%, 0, 0);
  }

  .carousel-inner .item.active.right {
    transform: translate3d(33%, 0, 0);
  }

  .carousel-inner .item.next {
    transform: translate3d(33%, 0, 0);
  }

  .carousel-inner .item.prev {
    transform: translate3d(-33%, 0, 0);
  }

  .carousel-inner .item.left, .carousel-inner .item.right {
    transform: translate3d(0, 0, 0);
  }
}