@import "_global_includes.scss";
@import "patch_mc";
@import "print";
@import "messaging";
@import "components/header_menu_sights";
@import "components/wdb_page_compare";
@import "components/object_footer";
@import "levelsystem/levelsystem_frontend";

/*
 * Let's target IE to respect aspect ratios and sizes for img tags containing SVG files
 *
 * [1] IE9
 * [2] IE10+
 */
/* 1 */
.ie9 img[src$=".svg"] {
  width: 100%;
}

/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}

/* -------------- BASIC ELEMENTS -------------- */

/*ista*/
@font-face {
  font-family: 'Exo2';
  src: url(../fonts/exo-2/Exo2.0-Regular.otf);
  font-weight: $body-font-weight;
}

@font-face {
  font-family: 'Exo2';
  src: url(../fonts/exo-2/Exo2.0-SemiBold.otf);
  font-weight: $body-font-weight;
}

@font-face {
  font-family: 'Exo2';
  src: url(../fonts/exo-2/Exo2.0-Italic.otf);
  font-style: italic;
}

@font-face {
  font-family: 'Exo2';
  src: url(../fonts/exo-2/Exo2.0-SemiBoldItalic.otf);
  font-style: italic;
  font-weight: 900;
}

//Theme fonts
@font-face {
  font-family: 'gilroy-black';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Black.otf);
}

@font-face {
  font-family: 'gilroy-bold';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Bold.otf);
  font-weight: 900;
}

@font-face {
  font-family: 'gilroy-light';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Light.otf);
}

@font-face {
  font-family: 'gilroy-regular';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Regular.otf);
}

@font-face {
  font-family: 'gilroy-black-italic';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Regular.otf);
  font-style: italic;
}

@font-face {
  font-family: 'gilroy-medium';
  src: url(../fonts/gilroy/Radomir-Tinkov-Gilroy-Medium.otf);
}

//Theme fonts
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'), url('../fonts/roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'), url('../fonts/roboto/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Italic'), url('../fonts/roboto/Roboto-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'), url('../fonts/roboto/Roboto-Light.woff') format('woff');
}


html {
  height: 100%;
  background-color: $body-background-color;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
}

body {
  font-family: $body-font-stack;
  color: $body-font-color;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  min-height: 100%;
  height: 100%;
  background-color: transparent;
  //*{
  //  box-shadow: none!important;
  //}
  p {
    font-size: $body-font-size;
  }

  a {
    color: $body-link-font-color;
    font-size: $body-link-font-size;
  }

  a:hover,
  a:active,
  a:focus {
    color: lighten($body-link-font-color, 42.5%);
    text-decoration: none;
  }

  a:focus {
    outline: none;
  }

  a.link-external:after {
    font-family: 'Font Awesome 5 Pro';
    @include fa-icon("external-link");
    margin-left: 3px;
    font-size: calc(100% - 2px);
  }

  .highlight {
    background-color: $body-highlight-background-color;
    color: $body-highlight-font-color;
  }

  img {
    max-width: 100%;
  }

  .disabled {
    cursor: not-allowed;
    background-color: $color-gray6;
    opacity: 1;
  }

  .fa {
    font-size: $body-icon-font-size;
  }

  h1, h2, h3, h4, h5 {
    font-family: $font-gilroy-bold;
  }

  h1 {
    font-size: $body-h1-font-size;
    margin: $body-h1-margin;
    font-weight: $body-h1-font-weight;

    @media (max-width: $screen-xs-max) {
      margin: $body-h1-margin-mobile;
      font-size: $body-h1-mobile-font-size;
    }

  }

  h2 {
    font-size: $body-h2-font-size;
    margin: $body-h2-margin;
    font-weight: $body-h2-font-weight;
    @media (max-width: $screen-xs-max) {
      font-size: $body-h2-mobile-font-size;
    }
  }

  h3 {
    font-size: $body-h3-font-size;
    margin: $body-h3-margin;
    font-weight: $body-h3-font-weight;
    @media (max-width: $screen-xs-max) {
      font-size: $body-h3-mobile-font-size;
    }
  }

  h4 {
    font-size: $body-h4-font-size;
    margin: $body-h4-margin;
    font-weight: $body-h4-font-weight;
  }

  h5 {
    font-size: $body-h5-font-size;
    margin: $body-h5-margin;
    font-weight: $body-h5-font-weight;
  }

  h6 {
    font-size: $body-h6-font-size;
    margin: $body-h6-margin;
    font-weight: $body-h6-font-weight;
  }

  .hide-bullets {
    list-style: none;
    margin-left: -40px;
    margin-top: 20px;
  }

  .js-marquee {
    line-height: 23px;
  }

  .alert.alert-warning {
    background: $body-alert-warning-background;
    border: $body-alert-warning-border;
    color: $body-alert-warning-font-color;
  }

  :focus {
    outline: none;
    outline-color: transparent;
    outline-width: 0;
  }
}

.toggle-managed-department-button-wrapper {
  display: inline-block;
  float: right;
}

/* -------------- HEADER -------------- */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

header {
  background-color: $header-background-color;
  background-image: $header-background-image;
  @include gradient($header-gradient-from-background-color, $header-gradient-to-background-color);

  #logo {
    float: left;
    // height: auto;
    //margin: 10px 0;
    @extend .img-responsive;
    height: 80px;
    @media screen and (max-width: $screen-xs-max) {
      height: auto;
    }
  }

  #slogan {
    float: left;
    height: 50px;
    color: $header-slogan-font-color;
    font-size: 25px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 6px;
    padding: 12px 0 0 30px;

    span {
      color: $theme-red;
      font-weight: 700;
    }

  }

  #slogan img {
    max-height: 100%;
  }

  .wrapper-user-menu {
    ul.menu-user.nav {
      margin-top: 0.8em;
      margin-right: 2em;
      font-size: $header-menu-font-size;
      color: $header-menu-font-color;

      li.dropdown:hover > ul.dropdown-menu {
        @media screen and (min-width: $screen-lg-min) {
          display: block;
        }
      }

      li.open > a,
      li.open > a:hover,
      li.open > a:focus {
        background-color: transparent;
      }

      li .dropdown-menu {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $header-menu-dropdown-menu-background-color;
        @media (max-width: $screen-sm-max) {
          position: relative;
        }
      }

      li li.dropdown::before {
        @include fa-icon(caret-left);
        color: $header-menu-dropdown-menu-link-font-color;
        font-size: $header-menu-dropdown-menu-link-font-size;
        left: 5px;
        position: absolute;
        font-family: "Font Awesome 5 Pro";
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: $screen-sm-max) {
          top: 9px;
          right: 18px;
          left: auto;
          transform: rotate(-90deg);
        }

      }

      > li > .nav > i {
        font-size: $header-menu-dropdown-menu-link-icon-font-size;
        color: $header-menu-dropdown-menu-link-icon-font-color;
      }

      > li > .dropdown-menu:before {
        @media (min-width: $screen-md-min) {
          position: absolute;
          top: -7px;
          right: 20px;
          display: inline-block;
          border-right: 7px solid transparent;
          border-bottom: 7px solid $color-gray14;
          border-left: 7px solid transparent;
          border-bottom-color: rgba(0, 0, 0, 0.2);
          content: '';
        }
      }

      > li > .dropdown-menu:after {
        position: absolute;
        top: -6px;
        right: 20px;
        display: inline-block;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $theme-white;
        border-left: 6px solid transparent;
        content: '';
      }

      ul.notification li.all a {
        position: relative;
      }

      ul.notification li a,
      ul.elearning li a {
        @media (min-width: $screen-lg-min) {
          white-space: normal;
          width: 200px;
        }
      }

      .sub-menu {
        .sub-menu {
          right: 100%;
          top: -5px;
        }

        i {
          margin-right: 5px;
          font-size: inherit;
          color: $header-menu-dropdown-menu-li-link-icon-font-color;
        }

        /* For changing tasks on eLearning dropdown menu label text */
        .label_text {
          font-weight: normal;
        }

        span.date {
          font-size: 0.85em;
          color: $theme-black;
        }

        li {
          color: $header-menu-dropdown-menu-font-color;
          border-bottom: 1px solid $placeholder-color;
          font-size: $header-menu-dropdown-menu-font-size;
          padding: 0 10px 0 10px;
          @media (max-width: $screen-sm-max) {
            border-color: $theme-white;
          }

          > span {
            @media (max-width: $screen-sm-max) {
              line-height: 20px;
              padding: 5px 15px 5px 25px;
            }
          }
        }

        li:hover {
          @media (min-width: $screen-lg-min) {

            background-color: $header-menu-dropdown-menu-link-background-color-hover;
            color: $header-menu-dropdown-menu-link-font-color-hover;
            > a {
              background-color: $header-menu-dropdown-menu-link-background-color-hover;
              color: $header-menu-dropdown-menu-link-font-color-hover;;
            }
            span {
              a, .date {
                background-color: $header-menu-dropdown-menu-link-background-color-hover;
                color: $header-menu-dropdown-menu-link-font-color-hover;
              }
            }
            i {
              color: $header-menu-dropdown-menu-link-icon-font-color-hover;
            }
          }
        }

        li:last-child {
          border-bottom: none
        }

        li.all {
          @media (min-width: $screen-lg-min) {
            text-align: center;
            background-color: $header-menu-dropdown-menu-all-link-background-color;
          }
          text-align: left !important;
        }

        li.all a {
          color: $header-menu-dropdown-menu-all-link-font-color;
          font-size: $header-menu-dropdown-menu-all-link-font-size;
          font-weight: initial;
          padding: 9px 0 3px;
        }

        li.all:hover,
        li.all:hover a,
        li.all a:hover {
          background-color: $header-menu-dropdown-menu-all-link-background-color-hover;
          color: $header-menu-dropdown-menu-all-link-font-color-hover;
        }

        li.all.active,
        li.all.active a {
          background: $placeholder-color;
          background: transparent;
          color: $placeholder-color;
        }

        li.all.active:hover a {
          color: $header-menu-dropdown-menu-all-link-font-color-hover;
        }

        li.all.active:hover {
          background: $header-menu-dropdown-menu-all-link-background-color-hover;
        }

        li.all:hover i {
          color: $header-menu-dropdown-menu-all-link-font-color-hover;
        }
      }

      li > a:hover,
      li > a:focus {
        background-color: transparent;
      }

      .badge-danger {
        background-color: $header-menu-badge-background-color;
        color: $header-menu-badge-font-color;
        font-size: $header-menu-badge-font-size;
        line-height: 12px;
        padding: 2px 5px;
        position: absolute;
        right: 7px;
        top: 8px;
      }

      .badge-count {
        background-color: $header-menu-badge-background-color;
        color: $header-menu-badge-font-color;
        font-size: 12px;
        line-height: 12px;
        padding: 2px 5px;
        float: right;
      }
    }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus {
      color: $theme-white;
      text-decoration: none;
      background-color: $placeholder-color;
      outline: 0;
    }
  }
}

.wrapper-search-global {
  padding-top: 18px;

  /* search form and live search */
  #live-search-result {
    display: none;
  }

  #live-search-result.loading {
    display: block;
    background-color: $search-header-live-background-color;
    color: $search-header-live-font-color;
    text-align: center;
    font-size: 20px;
    min-height: 30px;
    position: absolute;
    width: 100%;
    z-index: 1003;
    padding: 15px;

    div {
      display: none;
    }
  }

  #live-search-result.ready {
    background-color: $color-gray13;
    border: $search-header-live-background-color;
    border-top: none;
    display: block;
    min-height: 30px;
    position: absolute;
    //width: 150%;
    min-width: 240px;
    max-width: 500px;
    top: 100%;
    right: 0;
    z-index: 1003;
    background: $search-header-live-background-color;
    padding: 15px 0 15px 0;
    border: 1px solid;

    h4 {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      text-align: center;
      background-color: $theme-green;
      color: $theme-white;
      border-bottom: 1px solid $theme-green;
    }

    h5 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 11pt;

      i {
        display: inline;
        margin-right: 5px;
        margin-left: 5px;
      }

      &:hover i {
        color: $theme-green;
      }
    }

    i {
      display: none;
    }

    a {
      color: $search-header-live-font-color;
      font-size: $search-header-live-font-size;
    }

    a:hover {
      color: $search-header-live-font-color-hover;
    }

    a.search-live-result-item {
      color: $search-header-live-more-font-color;
      font-size: $search-header-live-more-font-size;
      padding-left: 5px;
    }

    a.search-live-result-item:hover {
      color: $search-header-live-more-font-color-hover;
    }

    a.search-live-result-item.search-live-result-more {
      font-size: 13px;
      font-weight: 600;
      font-style: italic;
    }
  }

  #search-form {
    position: relative;
    width: 100%;

    input, button {
      @include border-radius(0);

      .fa {
        font-size: $header-search-icon-font-size;
        color: $header-search-icon-font-color;
      }
    }

    .btn-default {
      background-color: $header-search-background-color;
      border: $header-search-border;
      border-left-color: transparent;
      padding: 7px 12px 5px 12px;
      height: 34px;
      -webkit-box-shadow: 0;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    input.form-control,
    input.form-control:focus {
      font-size: $header-search-font-size;
      color: $header-search-font-color;
      border: $header-search-border;
      border-right-color: transparent;
      box-shadow: none;
      border-right: 0;
    }
  }
}

.news-ticker {
  background: $header-newsticker-background-color;
  color: $header-newsticker-font-color;
  font-size: $header-newsticker-font-size;
  overflow: hidden;
  height: $header-newsticker-height;
  line-height: $header-newsticker-height;

  a {
    color: $header-newsticker-link-font-color;
    font-size: $header-newsticker-link-font-size;
  }

  a:hover {
    color: $header-newsticker-link-font-color;
  }
}

#wrapper {
  //background: $body-background;
  //background-image: $background-image;
  //background-repeat: no-repeat;
  //background-size: cover;
  padding-top: 30px;
  height: 100%;

  > .container {
    background: $placeholder-color;
    max-width: 960px;
  }
}

//Login page background
.action-login {
  background-image: url(../images/background.jpg);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-attachment: fixed;
}

/* Remove the white background behind the login screen */
body.site.login #wrapper > .container {
  background: transparent;

  .login_panel {
    background: transparent;
    box-shadow: none;

    .panel-default {
      border-color: $color-gray6;
    }

    input.btn-success {
      background-color: $theme-white;
      border-color: transparent;
      color: $btn-font-color;
      border-radius: 32px;
      display: block;
      margin: auto;
      padding: 0.6em 1.8em;
      box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;

      &:hover, &:focus {
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        -webkit-transition-duration: 0.1s;
        transition-duration: 0.1s;
        -webkit-transition-property: box-shadow;
        transition-property: box-shadow;
        box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
      }
    }
  }

  .login-logo {
    max-width: 40%;
  }

  .panel-heading {
    @include gradient();
  }
}

article .panel-heading {
  border-radius: 0;
  background-color: $content-wrapper-header-background-color;
  color: $content-wrapper-header-font-color;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $content-wrapper-header-border-bottom-color;

  h1 {
    font-size: $content-wrapper-header-font-size;
    color: $content-wrapper-header-font-color;
    margin-bottom: 0;
    margin-top: 5px;
  }

  i.widget-headline-icon {
    background-color: $content-wrapper-header-icon-background-color;
    color: $content-wrapper-header-icon-font-color;
    font-size: $content-wrapper-header-icon-font-size;
    height: 2em;
    margin-right: 1em;
    padding: 0.45em;
    width: 2em;
    position: absolute;
    left: 0;
    top: 0;
  }
}

// breadcrumb
div.breadcrumbs {
  padding-left: 50px;
  padding-right: 50px;
  float: none !important;
  background-color: $theme-white;
}

.breadcrumb {
  background: transparent;
  background: $content-wrapper-breadcrumbs-background-color;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;

  li {
    color: $content-wrapper-breadcrumbs-font-color;
    font-size: $content-wrapper-breadcrumbs-font-size;
    cursor: pointer;

    span {
      padding: 0 0 0 0;

      i {
        color: $content-wrapper-breadcrumbs-icon-font-color;
        font-size: $content-wrapper-breadcrumbs-icon-font-size;
        padding: 0 5px 0 0;
      }
    }

    i.fa-home {
      margin-right: 5px;
    }
  }

  li.hidden-ellipsis {
    display: none;
  }

  > li + li:before {
    color: $content-wrapper-breadcrumbs-font-color;
  }

  li:last-child {
    cursor: default;
    color: $content-wrapper-breadcrumbs-current-font-color;
    font-size: $content-wrapper-breadcrumbs-current-font-size;
  }
}

article > section.panel-body {
  min-height: 400px;
}

/* EDIT BUTTON GROUPS */
.page-edit-button-group,
.content-edit-button-group {
  position: absolute;
  top: 0;
  right: 0;

  p {
    padding: 3px 20px;
  }

  button {
    background-color: $content-wrapper-menu-icon-background-color;
    color: $content-wrapper-menu-icon-font-color;
    font-size: $content-wrapper-menu-icon-font-size;
    height: 2em;
    width: 2.5em;
    padding: 0;
    border-radius: 0;

    > .fa {
      color: $content-wrapper-menu-icon-font-color;
      font-size: $content-wrapper-menu-icon-font-size;
      background-color: $content-wrapper-menu-icon-background-color;
    }

    &:hover,
    &:focus,
    &.focus {
      color: $content-wrapper-menu-icon-font-color-hover;
      background-color: $content-wrapper-menu-icon-background-color-hover;

      > .fa {
        color: $content-wrapper-menu-icon-font-color-hover;
        background-color: $content-wrapper-menu-icon-background-color-hover;
      }
    }
  }

  .dropdown-menu > li {
    //padding-left: 15px;
    > p, strong {
      padding: 0;
      padding-left: 15px;
    }

    a {
      color: $content-wrapper-menu-link-font-color;
      background-color: $content-wrapper-menu-link-background-color;
      font-size: $content-wrapper-menu-link-font-size;
      position: relative;
      // padding-left: 25px;
      .fa, .far, .fas {
        color: $content-wrapper-menu-link-icon-font-color;
        font-size: $content-wrapper-menu-link-icon-font-size;
      }

      &:hover {
        color: $content-wrapper-menu-link-font-color-hover;
        background-color: $content-wrapper-menu-link-background-color-hover;

        .fa, .far, .fas {
          color: $content-wrapper-menu-link-icon-font-color-hover;
        }
      }
    }
  }

  .star-rating-control {
    display: inline-block;
  }

  .feedback-rating {
    padding-left: 20px;
  }


  // like count and rating average
  .valuation-display {
    display: flex;
  }

  #avg_rating_refresh {
    padding-top: 2px;
    padding-left: 5px;
    color: #777777;
  }

  #total_like_refresh {
    padding-top: 3px;
    padding-left: 5px;
    font-size: 14px;
    color: #777777;
  }

  .feedback-like {
    padding-right: 0px;
  }

  //
  //.feedback-text,
  //.abonnement-text,
  //.favorite .text {
  //  font-size: 15px;
  //}
  //.page-editorial-button {
  //background-color: $grey-light;
  //}
}

///* feedback */
//.feedback-like {
//  font-size: 15px;
//}
//
//.feedback-like i {
//  color: $placeholder-color;
//}
//
//.feedback-like i.fa-thumbs-up {
//  color: $placeholder-color;
//}
//
//.feedback-text,
//.abonnement-text {
//  font-size: 15px;
//}
//
//.abonnement-text,
//.feedback-text {
//  font-size: 15px;
//}
//
//.abonnement-text i,
//.feedback-text i {
//  color: $placeholder-color;
//}
//
//.favorite i {
//  color: $placeholder-color;
//  //font-size: 15px;
//  /*margin-top: 5px;*/
//}
//
//.feedback-wrapper {
//  border-bottom: $placeholder-color solid 1px;
//}

body.site.content .feedback-wrapper {
  border-right: $placeholder-color solid 1px;
}

.feedback-wrapper > div > div {
  padding-right: 0px;
}

.feedback-wrapper > div > div > div {
  padding-left: 0px;
  padding-right: 0px;
}

#star_rating_ajax {
  padding-top: 5px;
}

/* -------------- SIDEBARS-------------- */
#sidebar-left {
  min-height: 300px;
}

.controller-learn {
  #elearning-sidebar-left, #elearning-sidebar-right {
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
    //#eLearning_course_widget, #elearning-sidebar-left {
    //  max-height: 65vh;
    //}
    //.widget-content-wrapper {
    //    overflow-y: auto;
    //    max-height: 65vh;
    //    overflow-x: hidden;
    //}
    .front-page-widget {
      .widget-headline-title {
        i {
          height: 2em;
          width: 2em;
          color: #fff;
          font-size: 15pt;
          padding: 10px 40px 10px 10px;
          margin: auto;
          border-radius: 50%;
        }

        h3 {
          background-color: $theme-red;
          margin: 0;
          color: $theme-white;
          text-align: left;
          padding: 10px 0 10px 15px;
          float: none !important;
          line-height: 21px;
          font-size: 20px;
        }
      }

      .widget-content {
        div.row.widget-content-row.border-bottom {
          padding: 10.5px 15.5px;
          margin: 0;
          font-size: 14px;
          border-bottom: 1px solid $primary-color;
          color: #000;
          background-color: $theme-white;

          div.col-xs-12.widget-content-row-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
          }
        }

        div.row.widget-content-row.border-bottom:focus,
        div.row.widget-content-row.border-bottom:hover {
          background: $theme-red;
          color: $theme-white;

          i {
            color: #fff;
          }
        }

        .learn-topics {
          display: flex;
          flex-direction: column;

          a, div.row.widget-content-row.border-bottom {
            padding: 10.5px 15.5px;
            margin: 0;
            font-size: 14px;
            border-bottom: 1px solid $primary-color;
            color: #000;
            background-color: $theme-white;
          }

          .focus, :focus, :hover {
            background: $theme-red;
            color: $theme-white;
          }
        }
      }
    }
  }
}

#sidebar-right {
  //-webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  //-moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  //box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  //background-color: $content-sidebar-background-color;
  .news-teaser-title-headline {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    font-weight: 700;
  }

  .news-teaser-rubric {
    padding-top: 9px;
    color: $content-sidebar-rubric-font-color;
    font-size: $content-sidebar-rubric-font-size;
  }

  .content-news-teaser {
    .news-headline-title {
      margin-top: -8px;
      //margin-left: -15px;
      //margin-right: -15px;
      i {
        height: 2em;
        width: 2em;
        font-size: $content-sidebar-headline-icon-font-size;
        padding: 0.45em;
        margin-right: 10px;
      }

      h3 {
        font-size: $content-sidebar-headline-font-size;
        color: $content-sidebar-headline-font-color;
        background-color: $content-sidebar-headline-background-color;
        margin: 0;
        padding: 0;
        margin-top: 0.4em;
        padding-top: 8px;
        padding-bottom: 0.2em;
        float: none !important;
        //height: 1.6em;
        border-bottom: $content-sidebar-headline-border-bottom;
        line-height: 27px;
      }
    }


    .news-footer {
      background-color: $content-sidebar-footer-background-color;
      // margin-left: -15px;
      // margin-right: -15px;
      padding: 0.6em 0;
      font-size: 12pt;

      a {
        font-size: $content-sidebar-footer-font-size;
        color: $content-sidebar-footer-font-color;
      }

      &:hover a {
        color: $content-sidebar-footer-font-color-hover;
      }
    }
  }

  .news-teaser-list {
    -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
    background-color: $content-sidebar-background-color;

    h3 {
      padding: 0;
      padding-bottom: 0.2em;
      float: none !important;
    }

    .news-teaser-image img {
      width: 100%;
      max-width: 100%;
      height: auto;
      border: none;
    }

    .news-list-article {
      border-bottom: $content-sidebar-item-border-bottom;
      margin-bottom: 10px;
      margin-left: 0;
      padding: 10px;
      padding-left: 5px;
    }

    .news-list-article:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  .news-list-article {
    .news-teaser-date {
      color: $content-sidebar-item-date-font-color;
      font-size: $content-sidebar-item-date-font-size;
    }

    .news-list-content-wrapper {
      margin-left: 15px;
    }

    h3 {
      line-height: 1.1em;
      font-size: $content-sidebar-item-title-font-size;
      color: $content-sidebar-item-title-font-color;
      margin: 3px 0;
    }

    .news-teaser-text {
      font-size: $content-sidebar-item-teaser-font-size;
      color: $content-sidebar-item-teaser-font-color;
    }

    .news-readmore-link {
      display: none;
      font-size: $content-sidebar-item-link-font-size;
      color: $content-sidebar-item-link-font-color;

      &:hover {
        color: $content-sidebar-item-link-font-color-hover;
      }
    }
  }

  a.btn-news {
    background-color: $content-sidebar-footer-background-color;
    color: $content-sidebar-footer-font-color;
    font-size: $content-sidebar-footer-font-size;
    @include border-radius(0);
    margin-bottom: 20px;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: $content-sidebar-footer-font-color-hover;
    }
  }

  .empty {
    /*test*/

    display: block;
    text-align: center;
    font-size: 16px;
  }
}

#main-menu-wrapper {
  -webkit-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  -moz-box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
  box-shadow: 2px 2px 5px 0px rgba(153, 153, 153, 0.65);
}

/* PUSH MENU */
#sidebar-left.mobile {
  display: block !important;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1002;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.88);
  overflow-x: hidden;
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */

  h2 {
    i {
      margin-right: 0px;
      cursor: pointer;
    }

    .closebtnOverlay {
      padding-bottom: 5px;
      margin-right: 10px;
    }
  }

  h2.main-menu {
    display: block;
  }

  .inner {
    position: relative;
    width: 100%;

    .multilevelpushmenu_wrapper {
      position: static;
    }

    .overlap-menu {
      position: relative;
    }
  }
}

.btn-main-menu,
.btn-main-menu:focus,
.btn-main-menu:hover {
  background-color: $theme-blue;
  color: $theme-white;
  border-radius: 0;

  .fa-bars {
    color: $theme-white;
  }

  @media (max-width: $screen-sm-max) {
    font-size: 0;
  }
}

#sidebar-left {
  padding-left: 0;
  padding-right: 0;

  .inner {
    visibility: hidden;
  }

  h2.main-menu {
    display: none;
    color: $content-menu-headline-font-color;
    font-size: $content-menu-headline-font-size;

    .fa {
      color: $content-menu-headline-icon-font-color;
      font-size: $content-menu-headline-icon-font-size;
    }
  }

  /* Main Menu */
  .menu-client .floatRight,
  .menu-container .floatRight,
  .menu-page .floatRight {
    margin-right: -10px;
    margin-top: 4px;
  }

  .multilevelpushmenu_wrapper[data-level="1"] h2 {
    line-height: 1em;
    font-weight: bold;
    color: $content-menu-topheadline-font-color;
    background-color: $content-menu-topheadline-background-color;
    font-size: $content-menu-topheadline-font-size;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 0.7em;
    //padding: 10px;
    .fa {
      color: $content-menu-topheadline-icon-font-color;
      font-size: $content-menu-topheadline-icon-font-size;
    }
  }

  .multilevelpushmenu_wrapper h2 {
    line-height: 1em;
    font-weight: bold;
    color: $content-menu-headline-font-color;
    background-color: $content-menu-headline-background-color;
    font-size: $content-menu-headline-font-size;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 0.7em;
    min-height: 40px;
    border: 0;

    .fa {
      color: $content-menu-headline-icon-font-color;
      font-size: $content-menu-headline-icon-font-size;
    }
  }

  .multilevelpushmenu_wrapper li a {
    color: $content-menu-link-font-color;
    font-size: $content-menu-link-font-size;
    padding: 0.2em 1em 0.2em 0.75em;
    word-wrap: break-word;
  }

  .multilevelpushmenu_wrapper li a.current {
    color: $content-menu-current-link-font-color;
    font-size: $content-menu-current-link-font-size;
    word-wrap: break-word;
    width: 100% !important;
  }

  .multilevelpushmenu_wrapper li a:hover {
    color: $content-menu-link-font-color-hover;
  }

  .multilevelpushmenu_wrapper li {
    font-weight: normal;
    color: $content-menu-link-font-color;
    font-size: $content-menu-link-font-size;
    background: $content-menu-link-background-color;
  }

  .multilevelpushmenu_wrapper .fa-bars,
  .multilevelpushmenu_wrapper .fa-file-text-o,
  .multilevelpushmenu_wrapper .fa-reorder,
  .multilevelpushmenu_wrapper .fa-angle-left,
  .multilevelpushmenu_wrapper .fa-circle,
  .multilevelpushmenu_wrapper .fa-angle-right {
    color: $content-menu-link-icon-font-color;
    font-size: $content-menu-link-icon-font-size;
  }

  .multilevelpushmenu_wrapper .fa-angle-left,
  .multilevelpushmenu_wrapper .fa-angle-right {
    color: $content-menu-link-icon-font-color;
    font-size: $content-menu-link-icon-font-size;
    font-weight: 700;
  }

  .multilevelpushmenu_wrapper .levelHolderClass {
    //background: $content-menu-link-background-color;
    background: transparent;
    font-size: 1em;
    font-family: $body-font-stack;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    overflow: visible;
  }

  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .read_command_prefix,
  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .new_item_prefix,
  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .edited_item_prefix {
    color: $theme-green;
    font-size: .9em;
    padding: 2px 0;
    margin: 0 5px 0 0;
  }

  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .read_command_prefix .prefix_text,
  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .new_item_prefix .prefix_text,
  #main-menu-multilevelpushmenu_multilevelpushmenu.multilevelpushmenu_wrapper .levelHolderClass ul li a .edited_item_prefix .prefix_text {
    background: $theme-green;
    color: $theme-white;
    font-size: .65em;
    padding: 2px 5px;
    border-radius: 3px;
  }

  .multilevelpushmenu_wrapper .levelHolderClass li:hover {
    background: $content-menu-link-background-color-hover;
    color: $content-menu-link-font-color-hover;

    > a {
      color: $theme-white;

      .fa {
        color: $content-menu-link-icon-font-color-hover;
      }

      .new_item_prefix .prefix_text,
      .edited_item_prefix .prefix_text,
      .read_command_prefix .prefix_text {
        background: $theme-white !important;
        color: $theme-green !important;
      }

      .new_item_prefix,
      .edited_item_prefix,
      .read_command_prefix {
        color: $theme-white !important;
      }
    }

    //a.current {
    //  color: $content-menu-current-link-font-color-hover;
    //  font-size: $content-menu-current-link-icon-font-color-hover;
    //  background-color: $content-menu-current-link-background-color-hover;
    //  .fa {
    //    color: $content-menu-current-link-icon-font-color;
    //    background-color: $content-menu-current-link-background-color;
    //    font-size: $content-menu-current-link-icon-font-size;
    //  }
    //}
  }

  .multilevelpushmenu_wrapper .backItemClass {
    display: block;
    padding: .2em;
    background: $content-menu-link-background-color;
    border-top: $content-menu-link-border-top;
  }

  .multilevelpushmenu_wrapper .backItemClass:hover {
    background-color: $theme-green;

    a, .fa {
      color: $theme-white;
    }

  }

  .multilevelpushmenu_wrapper a {
    font-size: 1em;
    line-height: 1.4em;
    overflow: hidden;
    padding-right: 10px;
    //text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  .multilevelpushmenu_wrapper a.active {
    font-weight: 700;
  }

  .multilevelpushmenu_wrapper .multilevelpushmenu_inactive {
    background: $content-menu-background-color;
  }

  .multilevelpushmenu_wrapper div.multilevelpushmenu_inactive > ul > li > a.current {
    color: transparent;
  }

  .multilevelpushmenu_wrapper li:last-child {
    border-bottom: $content-menu-link-border-top;
    border-bottom-color: transparent;
  }

  .multilevelpushmenu_wrapper li {
    cursor: pointer;
    border-top: none;
    padding: .4em .4em .4em .4em;
  }
}

/* -------------- PROFILBILD -------------- */
.text-circle-user {
  padding: 5px 13px;
  margin-right: 5px;
}

.text-circle-header {
  background-clip: padding-box;
  border: 1px solid rgba(104, 135, 158, 0.506);
  border-radius: 50%;
  color: $placeholder-color;
  display: inline-block;
  font-size: 2em;
  height: 50px;
  padding: 10px 13px;
  width: 50px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center center !important;
  background-color: transparent;
}

.text-square-header {
  background-clip: padding-box;
  background-color: $placeholder-color;
  border-radius: 5%;
  color: $placeholder-color;
  display: inline-block;
  font-size: 2em;
  height: 50px;
  padding: 10px 13px;
  width: 50px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-position: 50% 50% !important;
}

.text-circle-user-message {
  padding: 4px 6px;
  margin-right: 4px;
}

.text-square-header-message {
  background-clip: padding-box;
  background-color: $placeholder-color;
  border-radius: 5%;
  color: $placeholder-color;
  display: inline-block;
  font-size: 2em;
  height: 40px;
  padding: 0 0 0 0;
  width: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-position: 50% 50% !important;
}

/* -------------- CONTENT -------------- */
body.site.login {
  .menu-button-wrapper {
    display: none;
  }

  footer {
    position: fixed;
    bottom: 15px;
    left: 15px;
    font-size: 12px;

    > span {
      cursor: pointer;
    }
  }

  .input-group .form-control {
    z-index: 0;
  }
}

.content-title {
  position: relative;
}

#page-content-wrapper > .container-fluid {
  background: $content-wrapper-background-color;
  color: $content-wrapper-font-color;
  font-size: $content-wrapper-font-size;
  min-height: 500px;
  margin: 0 15px 15px 15px;
  -webkit-box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65);
  -moz-box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65);
  box-shadow: 2px 2px 5px 0 rgba(153, 153, 153, 0.65);

  > .row:first-child {
    margin-top: 0;
  }

  .article-title {
    background: $content-wrapper-title-background-color;
    color: $content-wrapper-title-font-color;
    font-size: $content-wrapper-title-font-size;
  }
}

/*Abstand nach unten bei Bildern im Content*/
span[data-kms="media"] img {
  padding: 0 0 15px 0;
}

/*
// ACCORDION
#page-content-wrapper .panel-primary > .panel-heading,
#page-content-wrapper .panel-default > .panel-heading {
  border-radius: 4px;
  border: none;
  padding: 0px 0px 0px 0;
}

#page-content-wrapper .panel-primary > .panel-heading a,
#page-content-wrapper .panel-default > .panel-heading a {
  box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;
  border-radius: 32px;

  &:hover, &:active, &:focus {
    color: $theme-black !important;
    @include transform(perspective(1px) translateZ(0));
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    box-shadow: inset 0 0 0 2px $theme-green, 0 0 1px transparent;
  }
}

#page-content-wrapper .panel-primary > .panel-heading .panel-title,
#page-content-wrapper .panel-default > .panel-heading .panel-title {
  //color: $theme-black;
  //background-color: $theme-white;
  //display: block;
  //
  //a {
  //  color: $theme-black;
  //
  //}
}

#page-content-wrapper .panel-primary > .panel-heading .panel-title .accordion-count {
  display: none;
}

#page-content-wrapper .panel-primary > .panel-heading .panel-title .accordion-title,
#page-content-wrapper .panel-default > .panel-heading .panel-title a {
  padding: 12px 20px;
  display: block;
  line-height: 100%;
  border-radius: 32px;
  position: relative;

  &:before {
    content: "\f078";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-family: "Font Awesome 5 Pro";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    right: 30px;
  }

  .fa {
    margin-right: 15px;
  }
}

#page-content-wrapper .panel-primary > .panel-heading .panel-title a[aria-expanded="true"] .accordion-title,
#page-content-wrapper .panel-default > .panel-heading .panel-title a[aria-expanded="true"] {
  &:before {
    @include transform(rotate(180deg));
  }
}

#page-content-wrapper .panel-primary > .panel-heading .panel-title a[aria-expanded="true"] .accordion-count {
  color: $theme-black;
  display: none;
}

#page-content-wrapper .panel-default > .panel-heading .panel-title a[aria-expanded="true"] {
  color: $theme-black;

}

#page-content-wrapper .panel-primary,
#page-content-wrapper .panel-default {
  border: none;
  margin-bottom: 15px;
}

#page-content-wrapper .panel-warning > .panel-heading + .panel-collapse > .panel-body {
  background: $theme-white;
  border: none;
}

#page-content-wrapper .panel-default > .panel-heading + .panel-collapse > .panel-body {
  //background: #b0b0b1;
  // border: none;
}

#page-content-wrapper #accordion .panel-primary > .panel-heading + .panel-collapse > .panel-body,
#page-content-wrapper #accordion .panel-default > .panel-heading + .panel-collapse > .panel-body {
  background: $theme-white;
  //margin-left: 40px;
  border: none;
}

// ACCORDION

*/

/* TABS */
#page-content-wrapper ul.nav-tabs li {
  margin-top: 1px;

  a {
    background: $content-wrapper-tabs-head-link-background;
    color: $content-wrapper-tabs-head-link-color;
    font-size: $content-wrapper-tabs-head-link-font-size;
    padding: 10px 40px;
    height: auto;
    border: 0;
    overflow: hidden;
    @media (max-width: $screen-xs-max) {
      padding: 10px;
      font-size: 11pt;
    }

    &:hover, &:focus {
      border-bottom: 3px solid $theme-green;
      box-shadow: none;
      color: $theme-green;
      background-color: $content-wrapper-tabs-head-link-background;
    }
  }

  &.active {
    a {
      background: $content-wrapper-tabs-head-link-background;

    }
  }

}

#page-content-wrapper ul.nav-tabs {
  border-bottom: none;
}

#page-content-wrapper ul.nav-tabs li.active a,
#page-content-wrapper ul.nav-tabs li.active a:hover {
  background: $content-wrapper-tabs-head-link-active-background;
  color: $content-wrapper-tabs-head-link-active-color;
  font-size: $content-wrapper-tabs-head-link-active-font-size;
  border-bottom: 3px solid $theme-red;
  @media (max-width: $screen-xs-max) {
    font-size: 11pt;
  }
}

#page-content-wrapper .tab-content {
  .tab-pane.active {
    padding: 15px;
    background: $content-wrapper-tabs-content-background;
    color: $content-wrapper-tabs-content-color;
    font-size: $content-wrapper-tabs-content-font-size;

    ul {
      //list-style: none !important;
      //padding: 0 !important;
      li {
        position: relative;
        padding-left: 30px;

        .fa {
          position: absolute;
          left: 0;
        }
      }
    }
  }

}

/*TABS*/
/*PANEL*/
#page-content-wrapper #news-content .panel-default {
  // border-color: $placeholder-color;
  // border-style: solid;
  // border-width: 1px;
}

/*PANEL*/
/*TABLES*/
.block-view-data_table table,
table.custom {
  border: 0;
  color: $content-wrapper-table-font-color;
  font-size: $content-wrapper-table-font-size;
  background: $content-wrapper-table-background;
  border-collapse: collapse;

  thead {
    tr.filters {
      background: $content-wrapper-table-filter-background;
    }

    tr:first-child {
      border: $content-wrapper-table-head-border;
      border-bottom: $content-wrapper-table-head-border-bottom;
      color: $content-wrapper-table-head-font-color;
      font-size: $content-wrapper-table-head-font-size;
      background: $content-wrapper-table-head-background;

      th, td {
        padding: 10px;

        a {
          color: $content-wrapper-table-head-font-color;

          &:hover,
          &:focus,
          &:active {
            color: $content-wrapper-table-head-font-color;
          }
        }
      }
    }
  }

  tfoot tr {
    border: $content-wrapper-table-foot-border;
    border-bottom: $content-wrapper-table-foot-border-bottom;
    color: $content-wrapper-table-foot-font-color;
    font-size: $content-wrapper-table-foot-font-size;
    background: $content-wrapper-table-foot-background;
  }

  tbody tr:nth-child(even),
  tbody tr.even {
    border: $content-wrapper-table-row-even-border;
    color: $content-wrapper-table-row-even-font-color;
    font-size: $content-wrapper-table-row-even-font-size;
    background: $content-wrapper-table-row-even-background;
    border-bottom: $content-wrapper-table-row-even-border-bottom;
  }

  tbody tr:nth-child(odd),
  tbody tr.odd {
    border: $content-wrapper-table-row-odd-border;
    color: $content-wrapper-table-row-odd-font-color;
    font-size: $content-wrapper-table-row-odd-font-size;
    background: $content-wrapper-table-row-odd-background;
    border-bottom: $content-wrapper-table-row-odd-border-bottom;
  }

  td {
    color: $content-wrapper-table-font-color;
  }

  td, th {
    padding: 5px;
    border: 0px dotted $theme-black;
  }

  .table-empty thead {
    display: none;
  }
}

//table {
//  width: 100%;
//  tr, td {
//    padding: 15px;
//  }
//  th {
//    text-align: center;
//  }
//  tr {
//    &:first-of-type {
//      td:not(:first-of-type) {
//        text-align: center;
//      }
//      td{
//        font-weight: 700;
//        border-bottom: 1px solid $border-color;
//      }
//    }
//    td{
//      border-bottom: 1px solid $color-gray11;
//    }
//  }
//  td:not(:first-of-type) {
//    text-align: center;
//  }
//}

#message-grid thead tr,
tbody tr:nth-child(even) {
  //background: $color-gray11;
  //color: $theme-white;
}

#message-grid tbody tr:nth-child(odd) {
  background: $placeholder-color;
}

#message-grid .well.deins {
  border-color: $placeholder-color;
}

#message-grid .well.text {
  background: $placeholder-color;
}

#message-grid a {
  text-decoration: none;
}

#message-grid a,
#message-grid a:hover,
#message-grid a:visited,
#message-grid a:focus,
#message-grid a:active {
  color: $placeholder-color;
}

#message-grid a .panel-heading h5 {
  color: $placeholder-color;
}

#message-grid a.btn {
  color: $placeholder-color;
}

#message-grid .usr-bg-image {
  background-size: contain;
  background-repeat: no-repeat;
}

#message-grid .usr-bg-image i.fa {
  background-size: contain;
  background-repeat: no-repeat;
}

/* ERROR PAGES */
#page-content-wrapper .error-page .panel-footer {
  display: none;
}

.error-page {
  background: $content-error-background-color;
  color: $content-error-font-color;
  font-size: $content-error-font-size;

  .panel-body {
    min-height: 420px;
  }

  .article-title {
    background: $content-error-title-background-color;
    color: $content-error-title-font-color;
    font-size: $content-error-title-font-size;
  }

  .panel-heading {
    background-color: $content-error-header-background-color;
    color: $content-error-header-font-color;
    border-bottom: 1px solid $content-error-header-border-bottom-color;

    h1 {
      font-size: $content-error-header-font-size;
      color: $content-error-header-font-color;
      padding: 8px 50px 15px;
      margin: 0;
    }

    i.widget-headline-icon {
      background-color: $content-error-header-icon-background-color;
      color: $content-error-header-icon-font-color;
      font-size: $content-error-header-icon-font-size;
    }
  }
}

/*TABLES*/

/*Widgets*/
.widget-small.el {
  background: $placeholder-color;
  display: block;
}

.widget-small {
  background: $placeholder-color;
  min-height: 7vw;
  color: $placeholder-color;
}

.widget-small i[class*="fa-"] {
  font-size: 2.9em;
  padding: 10px;
  padding-top: 15px;
  color: $placeholder-color;
}

.widget-small p {
  font-size: 1em;
  color: $placeholder-color;
}

.row.first {
  margin-bottom: 20px;
}

.multilevelpushmenu_wrapper .ltr {
  margin-left: -100%;
  left: 0;
  -moz-box-shadow: 5px 0 5px -5px $placeholder-color;
  -webkit-box-shadow: 5px 0 5px -5px $placeholder-color;
  box-shadow: 5px 0 5px -5px $placeholder-color;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#1f4164, direction=90, strength=2);
}

.multilevelpushmenu_wrapper .rtl {
  margin-right: -100%;
  right: 0;
  -moz-box-shadow: 5px 0 5px 5px $placeholder-color;
  -webkit-box-shadow: 5px 0 5px 5px $placeholder-color;
  box-shadow: 5px 0 5px 5px $placeholder-color;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#1f4164, direction=270, strength=2);
}

.welcome-text,
.widget-small,
.front-welcome-image,
.front-page-widget-notification,
.front-page-widget-elearnings,
.front-page-widget-notification-wrapper,
.front-page-widget-favorites,
.front-page-widget-notices,
.front-welcome-image,
.link-widget,
.link-widget-special,
.front-page-widget,
.front-slider,
.front-page-widget-special,
#yw2_multilevelpushmenu {
  -webkit-box-shadow: 2px 2px 5px 0px $placeholder-color;
  -moz-box-shadow: 2px 2px 5px 0px $placeholder-color;
  box-shadow: 2px 2px 5px 0px $placeholder-color;
}

div.front-page-widget-favorites div.widget-content div.widget-content-row-text a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.text-delete-button.fa-times {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: $placeholder-color;
}

#form-filter-search {
  .btn-default {
    border: 0;
    @include round-border;

    &:hover, &:focus {
      background-color: $theme-white;
    }
  }

  .button-row {
    margin-top: 5px;
  }
}

//body:not(.index.site) #page-content-wrapper > .container-fluid

@media screen and (max-width: 768px) {

  #page-content-wrapper > .container-fluid {
    margin: 0;
  }

  #wrapper {
    padding-top: 0px;
  }

  #page-content-wrapper,
  #sidebar-right {
    //border-bottom: 3px solid $placeholder-color;
    min-height: 50px;
    background-color: $theme-white;
    margin-bottom: 15px;
    @media (max-width: $screen-sm-max) {
      background-color: transparent;
    }
  }
  .breadcrumb {
    li:not(:first-child):not(:last-child):not(.hidden-ellipsis) {
      display: none;
    }

    li.hidden-ellipsis {
      display: inline-block;
    }
  }

  body header .menu-user.navbar-nav {
    //min-height: 30px;
    //.nav > li > a {
    //  position: relative;
    //  display: block;
    //  padding: 10px 15px;
    //}
    //> li {
    //  float: left;
    //}
    //.nav > li {
    //  position: relative;
    //  display: block;
    //}
    //.open .dropdown-menu {
    //  //position: absolute;
    //}
  }
  //header menu item
  header .wrapper-user-menu ul.menu-user.nav > li > .nav > i {
    font-size: 18pt;
  }
}

@media screen and (max-width: 992px) {
  #sidebar-left .multilevelpushmenu_wrapper h2 .fa {
    display: block !important;
  }
  header .wrapper-search-global,
  header .wrapper-btn-main-menu {
    padding: 0px;
  }
  header .wrapper-btn-main-menu {
    display: none;
  }

  /* head menu */
  header .menu-user {
    margin-right: 0em;
    min-height: 70px;
  }

  .menu-user.navbar-nav > li {
    float: left;
  }

  // hide news on normal page
  #sidebar-right {
    display: none;
  }

  // show nes on front page
  .site.index #sidebar-right {
    margin-top: 20px;
    display: block;

    div.inner {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .wrapper-user-menu {
    min-height: 80px;
  }
}

@media screen and (max-width: 768px) {
  #sidebar-right {
    div.inner {
      padding-right: 30px !important;
      padding-left: 30px !important;
    }
  }
}

@media screen and (max-width: 991px) {
  .site.index #sidebar-right {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  #sidebar-right {
    //margin-left: 25%;
    //width: 75%;
    div.inner {
      padding-right: 30px;
      padding-left: 30px;
    }

    &.sidebar-right-moved {
      //margin-left: 8.33333333333%;
    }
  }
}

.front-page-widget-notices {
  .widget-footer .text-info small {
    cursor: pointer;
    color: $theme-white;
  }

  .btn-block {
    margin-bottom: 5px;
  }
}

div.front-page-widget-gallery-wrapper {
  background-color: $theme-black-dark;
  color: #fff;

  .widget-headline-title h3,
  * {
    background-color: $theme-black-dark;
    color: #fff;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .widget-headline-title h3 {

  }

  .front-page-widget-gallery-icon {
    color: $theme-green;
    font-size: 1.3em;
  }

  img {
    height: 100%;
    width: 1.8em;
    padding: 0.45em 0.3em 0.3em 0;
    margin-right: 10px;
  }

  .widget-content {
    margin-top: 7px;
    color: #fff;
    min-height: inherit;
    border-bottom: 1px dotted #ffffff;
    font-size: 1.03em;

    &:last-child {
      border-bottom: none;
    }
  }
}

.welcome-text {
  background-color: $placeholder-color;
}

.feedback-modal .modal-body .form-control,
.support-modal .modal-body .form-control {
  margin-bottom: 10px;
}

.modal-dialog {
  @media (min-width: $screen-sm-min) {
    width: 700px;
    .produkt-item-info.content-inner {
      &.col-sm-6 {
        width: 66.66666667%;
        margin-left: 16.66666667%;
      }
    }
  }
}

/* FORM */

.form-control,
textarea,
.input-group .input-group-addon,
.dropdown-menu {
  border-radius: 0px;
}

.help-block {
  font-size: $body-helpblock-font-size;
  color: $body-helpblock-font-color;

  > .fa {
    font-size: $body-helpblock-font-size;
    color: $body-helpblock-icon-font-color;
  }
}

.btn-success,
.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background: $body-btn-success-background;
  border: $body-btn-success-border;
  color: $body-btn-success-font-color;
}

/* pager */
.pager,
.paging_simple_numbers {
  margin-top: 20px !important;

  ul.pagination,
  ul.yiiPager {
    > li > a {
      border-radius: 2px;
      padding: 6px 8px;
      font-size: $pager-link-font-size;
      border: $pager-link-border;
      color: $pager-link-font-color;
      background: $pager-link-background-color;
      margin-bottom: 10px;

      &:link,
      &:visited,
      &:hover {
        margin-left: 5px;
        padding: 6px 8px;
        //border: $pager-link-border-hover;
        //color: $pager-link-font-color-hover;
        background: $pager-link-background-color-hover-non-active;
        border: $pager-link-background-color-border-hover-non-active;
        color: $pager-link-font-color-hover;

      }
    }

    > li.active > a,
    > li.selected > a {
      font-size: $pager-current-link-font-size;
      border: $pager-current-link-border;
      color: $pager-current-link-font-color;
      background: $pager-current-link-background-color;
      padding: 6px 8px;

      &:link,
      &:visited,
      &:hover {
        border: $pager-link-background-border-color-hover-active;
        color: $pager-current-link-font-color-hover;
        background: $pager-link-background-color-hover-active;
      }
    }

    > li.next > a,
    > li.previous > a {
      font-size: $pager-special-link-font-size;
      border: $pager-special-link-border;
      color: $pager-special-link-font-color;
      background: $pager-special-link-background-color;
      padding: 6px 8px;

      &:link,
      &:visited,
      &:hover {
        border: $pager-special-link-border-hover;
        color: $pager-special-link-font-color-hover;
        background: $pager-special-link-background-color-hover;
      }
    }

    > li.previous {
      margin-left: 5px;
    }

    > li.next {
      margin-right: 5px;
    }
  }
}

// snt phone list //
.snt-phone-list {
  height: 40px;
  font-family: $placeholder-color;
  background-color: $placeholder-color;

}

// title and breadcrumps //
.snt-phone-list-icon {
  background-color: $placeholder-color;
  color: $placeholder-color;
  font-size: 1.5em;
  height: 40px;
  //margin-right: 1em;
  padding: 0.45em;
  width: 2em;

}

.snt-phone-list-breadcrumps-link {
  background-color: $placeholder-color;
  height: 2em;
  color: $placeholder-color;
  font-size: 1.1em;
  padding: 0.6em;
  text-decoration: none;
  width: 100px;
  padding: 8px 15px;

  a:hover {
    color: $placeholder-color;
    text-decoration: none;
  }
}

.snt-phone-list-breadcrumps {
  background-color: $placeholder-color;
  color: $placeholder-color;
  height: 2em;
  font-size: 1.1em;
  padding: 8px 15px;
}

.phone-list-button {
  margin-top: 20px;
  margin-left: 0px;
}

// snt phone list table & snt job_list
#snt_phone_list-grid, #snt_job_list-grid {

  th {
    //background-color: $menu-background;
    background-color: $placeholder-color;
    color: $placeholder-color;
    /* sort */
    a.sort-link {
      color: $placeholder-color;
    }

    a.sort-link {
      text-decoration: none;
      display: block;
    }

    a.sort-link:after {
      font-family: 'Font Awesome 5 Pro';
      @include fa-icon("sort");
      color: $placeholder-color;
      padding-left: 10px;
    }

    .sort-link.asc:after, .sort-link.desc:after {
      font-family: 'Font Awesome 5 Pro';
      position: relative;
      color: $placeholder-color;
    }

    .sort-link.asc:after {
      @include fa-icon("sort-asc");
    }

    .sort-link.desc:after {
      @include fa-icon("sort-desc");
    }
  }

  tr {
    background-color: $placeholder-color;
  }

  tr.info > td {
    background-color: $placeholder-color;
  }

  tr:hover > td {
    background-color: $placeholder-color;
  }

  tr.filters > td {
    padding: 0px;
    background-color: $placeholder-color;

    input {
      width: 100%;
    }
  }

  tr.filters:hover > td {
    background-color: $placeholder-color;
  }

}

#snt_job_list-grid {
  td > div.row {
    margin-left: 0px;
  }
}

#public-news {
  .news-teaser-image {
    img {
      height: 130px;
      @media (max-width: $screen-xs-max) {
        height: 130px;
      }
    }
  }

  .news-text-wrapper {
    @media (max-width: $screen-xs-max) {
      margin-top: 10px;
    }
  }
}

.form-control {
  border: 1px solid $theme-gray-light;

  &:focus {
    border-color: $theme-green;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    box-shadow: inset 0 0 0 1px $theme-green, 0 0 1px transparent;
  }
}

.btn-success {
  color: inherit;
  @include round-border;
}

.btn-default {
  @include round-border;
  border: 0;

  &:hover, &:focus {
    background-color: $theme-white;
  }
}

/* sntNachrichtensystem */
//Conversation
//$chat-primary-color: $theme-white;
//$chat-secondary-color: $body-background-color;
//$chat-border-color: $border-color;
//$chat-icon-color: $theme-black;
//$chat-window-background: #ffffff;
//$chat-user-background-color: $theme-gray-light;
//$chat-admin-background-color: $theme-white;

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.dropdown-menu > li {
  //margin-left: 15px;
  > p {
    padding: 0;
  }

  a {
    font-size: 14px;
    position: relative;

    //.fa {
    //  position: absolute;
    //  left: 15px;
    //}

  }
}

//header .menu-user li .dropdown-menu {
//  &.conversation {
//    @media (min-width: $screen-sm-min) {
//      width: 250px;
//    }
//    > li {
//      border-bottom: 0;
//      &.all {
//        // border-top: 1px solid $chat-border-color;
//        text-align: left;
//      }
//      a {
//        padding: 0;
//        font-weight: 700;
//        cursor: pointer;
//        text-overflow: ellipsis;
//        overflow: hidden;
//        .message {
//          font-weight: 300;
//          margin: 5px 0;
//          white-space: normal;
//          p {
//            margin-bottom: 0;
//          }
//          .image {
//            float: left;
//            width: 25%;
//            img {
//              height: auto;
//              width: 35px;
//            }
//          }
//          .message-text {
//            float: right;
//            width: 75%;
//            .text {
//              font-style: italic;
//              //width: 170px;
//              p {
//                //white-space: nowrap;
//                //overflow: hidden;
//                //text-overflow: ellipsis;
//
//              }
//            }
//            .name {
//              font-size: 10pt;
//              font-weight: 700;
//            }
//            .time {
//              font-size: 8pt;
//            }
//          }
//        }
//      }
//    }
//  }
//}
//
//.conversation {
//  > li {
//    border-bottom: 0;
//  }
//
//}
//
//.conversation-block, .conversation-history-block {
//  .panel-body {
//    min-height: 0;
//  }
//  .panel-title {
//    border-bottom: 0px solid $chat-border-color;
//  }
//}
//
//.dropdown-menu {
//  a {
//    cursor: pointer;
//  }
//}
//
//.conversation-history {
//  display: inline-block;
//  margin-right: 15px;
//  padding: 10px 0;
//
//  i {
//    margin-right: 5px;
//  }
//  .date-create {
//    display: none;
//    margin-left: 5px;
//
//  }
//}
//
//.conversation-item-wrapper {
//  margin-bottom: 50px;
//
//}
//
//.conversation-history-select {
//  padding: 15px 60px 100px;
//  @media (max-width: $screen-xs-max) {
//    padding: 0;
//  }
//  .form-horizontal {
//    margin-bottom: 15px;
//  }
//}
//
//.conversation-history-wrapper {
//  position: relative;
//  padding: 15px 60px 0;
//  border-top: 1px solid;
//  min-height: 80px;
//  @media (max-width: $screen-xs-max) {
//    padding: 10px 0;
//  }
//  .dropdown-menu > li {
//    margin-left: 15px;
//    > p {
//      padding: 0;
//    }
//    a {
//      color: $content-wrapper-menu-link-font-color;
//      background-color: $content-wrapper-menu-link-background-color;
//      font-size: $content-wrapper-menu-link-font-size;
//      position: relative;
//      padding-left: 25px;
//      .fa {
//        color: $content-wrapper-menu-link-icon-font-color;
//        font-size: $content-wrapper-menu-link-icon-font-size;
//        position: absolute;
//        left: 0;
//      }
//      &:hover {
//        color: $content-wrapper-menu-link-font-color-hover;
//        background-color: $content-wrapper-menu-link-background-color-hover;
//        .fa {
//          color: $content-wrapper-menu-link-icon-font-color-hover;
//        }
//      }
//    }
//  }
//  .navbar-right {
//    position: absolute;
//    right: 0;
//    top: 0;
//    margin: 0;
//    z-index: 1000;
//    .dropdown-toggle {
//      background-color: $chat-primary-color;
//      height: 1.5em;
//      width: 2em;
//      font-size: 16pt;
//      padding: 0;
//      vertical-align: middle;
//      text-align: center;
//      line-height: 1.5em;
//      i {
//        color: $chat-icon-color;
//      }
//      &:hover, &:focus {
//        background-color: $chat-primary-color;
//        i {
//          color: $chat-icon-color;
//        }
//      }
//    }
//    .open {
//      .dropdown-toggle {
//        display: inline-block;
//        float: right;
//      }
//      .sub-menu.conversation {
//        background-color: $chat-window-background;
//
//        @media (max-width: $screen-xs-max) {
//          margin-top: 32px;
//          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
//          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
//        }
//
//      }
//    }
//  }
//  .conversation-person-info {
//  }
//}
//
//.selection-title {
//  border-bottom: 1px solid $chat-border-color;
//  font-weight: 900;
//}
//
//.notice-highlight {
//  padding: 10px 0 0;
//  color: $theme-green;
//  i {
//    font-size: 9pt;
//    color: $theme-green;
//  }
//  .time-period, b {
//    font-size: 9pt;
//  }
//}
//
//.conversation-group {
//  padding: 20px 0;
//  border-top: 1px solid $chat-border-color;
//  position: relative;
//  .date-created {
//    display: block;
//    font-size: 8pt;
//
//    @media (max-width: $screen-xs-max) {
//      margin-right: 100px;
//    }
//
//  }
//  .dropdown-menu > li {
//    margin-left: 15px;
//    > p {
//      padding: 0;
//    }
//    a {
//      color: $content-wrapper-menu-link-font-color;
//      background-color: $content-wrapper-menu-link-background-color;
//      font-size: $content-wrapper-menu-link-font-size;
//      position: relative;
//      padding-left: 25px;
//      .fa {
//        color: $content-wrapper-menu-link-icon-font-color;
//        font-size: $content-wrapper-menu-link-icon-font-size;
//        position: absolute;
//        left: 0;
//      }
//      &:hover {
//        color: $content-wrapper-menu-link-font-color-hover;
//        background-color: $content-wrapper-menu-link-background-color-hover;
//        .fa {
//          color: $content-wrapper-menu-link-icon-font-color-hover;
//        }
//      }
//    }
//  }
//  .navbar-right {
//    margin-right: 0;
//    background-color: $chat-primary-color;
//    position: absolute;
//    right: 0px;
//    top: 0;
//    margin: 0;
//    z-index: 1000;
//
//    .open {
//      .dropdown-toggle {
//        display: inline-block;
//        float: right;
//      }
//      .sub-menu.conversation {
//        background-color: $chat-window-background;
//        @media (max-width: $screen-xs-max) {
//          margin-top: 32px;
//          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
//          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
//        }
//
//      }
//    }
//
//    .dropdown-toggle {
//      height: 1.5em;
//      width: 2em;
//      font-size: 16pt;
//      padding: 0;
//      vertical-align: middle;
//      text-align: center;
//      line-height: 1.5em;
//      i {
//        color: $chat-icon-color;
//      }
//      &:hover, &:focus {
//        background-color: $chat-primary-color;
//        i {
//          color: $chat-icon-color;
//        }
//      }
//    }
//  }
//  #MembersOfConversation {
//    .user-details {
//      display: inline-block;
//      margin-bottom: 15px;
//      text-align: center;
//      &.admin {
//        img {
//          border: 2px solid $theme-blue;
//        }
//      }
//
//      a {
//        display: block;
//        margin-bottom: 10px;
//        img {
//          margin: auto;
//        }
//      }
//      .user-name {
//        display: block;
//        word-wrap: break-word;
//        margin-top: 5px;
//        text-align: center;
//
//      }
//    }
//  }
//}
//
//.unread-messsages {
//  margin-bottom: 5px;
//  .message {
//    overflow: hidden;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//  }
//}
//
//.conversation-window {
//  background-color: $chat-secondary-color;
//  margin-bottom: 30px;
//  padding: 30px;
//  #MembersOfConversation {
//    @media (min-width: $screen-lg-min) {
//      width: 800px;
//    }
//
//    margin: 0 auto;
//    @include clearfix;
//    .user-department {
//      display: none;
//    }
//    .user-details {
//      text-align: center;
//      // float: left;
//      // padding: 15px 0 15px 50px;
//      img {
//        margin: auto;
//      }
//      @media (max-width: $screen-xs-max) {
//        //float: none;
//        //margin: 10px 0;
//        //font-size: 10pt;
//        //display: inline-block;
//        //margin-right: 8px;
//      }
//      &:first-of-type {
//        margin-left: 0;
//      }
//      .user-name {
//        display: block;
//        margin-top: 5px;
//        word-wrap: break-word;
//      }
//    }
//  }
//  #btnPrevMessages {
//    position: relative;
//    .previous-messages {
//      position: absolute;
//      top: 0;
//      left: -25px;
//      z-index: 10;
//      @media (max-width: $screen-xs-max) {
//        top: -25px;
//        left: -13px;
//      }
//    }
//  }
//  .btn-load {
//    font: normal normal normal 14px/1 FontAwesome;
//    font-size: inherit;
//    text-rendering: auto;
//    -webkit-font-smoothing: antialiased;
//    -moz-osx-font-smoothing: grayscale;
//    transform: translate(0, 0);
//    background: transparent;
//    border: 0;
//  }
//  .conversation-detail {
//    margin-bottom: 40px;
//    @media (max-width: $screen-xs-max) {
//      font-size: 10pt;
//    }
//    .conversation-text {
//
//      @include box-shadow(1px, 1px, 1px, 0px, #000);
//      padding: 15px;
//      position: relative;
//    }
//    .user-image {
//      img {
//        width: 35px;
//        height: auto;
//      }
//      .user-name {
//        display: block;
//        margin-top: 5px;
//        word-wrap: break-word;
//        @media (max-width: $screen-xs-max) {
//          display: none;
//        }
//      }
//    }
//    .last-seen {
//      position: absolute;
//
//      a {
//        text-decoration: none;
//      }
//      button {
//        background: transparent;
//        outline: 0;
//        border: 0;
//      }
//    }
//    &.left {
//      float: left;
//      @media (max-width: $screen-xs-max) {
//        padding-left: 0;
//      }
//      .last-seen {
//        left: -5px;
//      }
//
//      .conversation-text {
//        background-color: $chat-admin-background-color;
//        &:before {
//          border-left: 0px solid transparent;
//          border-right: 21px solid transparent;
//          border-top: 16px solid #fff;
//          bottom: -13px;
//          left: 0;
//          position: absolute;
//          content: '';
//        }
//
//      }
//    }
//    &.right {
//      float: right;
//
//      @media (max-width: $screen-xs-max) {
//        padding-right: 0;
//      }
//      .last-seen {
//        right: -5px;
//      }
//      .user-image {
//        float: right;
//        text-align: right;
//      }
//      .conversation-text {
//        background-color: $chat-user-background-color;
//        &:before {
//          border-left: 21px solid transparent;
//          border-right: 0px solid transparent;
//          border-top: 16px solid $chat-user-background-color;
//          bottom: -13px;
//          right: 0;
//          position: absolute;
//          content: '';
//          box-shadow: 1px 0px 0px 0px #000;
//          -webkit-box-shadow: 1px 0px 0px 0px #000;;
//          -moz-box-shadow: 1px 0px 0px 0px #000;;
//        }
//      }
//    }
//    .last-seen {
//      position: absolute;
//      right: -5px;
//      a {
//        text-decoration: none;
//      }
//      button {
//        background: transparent;
//        outline: 0;
//        border: 0;
//      }
//    }
//    .date {
//      text-align: right;
//      font-size: 8pt;
//    }
//  }
//  .send-button {
//    float: right;
//    margin-top: 30px;
//  }
//}
//
//.group-header-text {
//  font-size: 10pt;
//  display: block;
//  text-align: left;
//  margin: 15px 0 10px 0;
//}
//
//.group-selection-headiline, .person-selection-headiline {
//  font-weight: 700;
//  margin: 15px 0;
//  @media (max-width: $screen-xs-max) {
//    font-size: 10pt;
//  }
//}
//
//.group-selection-headiline {
//  margin: 15px 0;
//}
//
//.buttons-group {
//  .btn {
//    margin: 20px 0;
//  }
//}
//
//.table-responsive {
//  border: 0;
//}
//
//#myUserTable, #myGroupTable {
//  width: 100%;
//  @media (max-width: $screen-xs-max) {
//    font-size: 10pt;
//  }
//  tr {
//    background: transparent;
//    td {
//      padding-bottom: 10px;
//      color: $body-font-color !important;
//      .department-icon {
//        font-size: 15pt;
//      }
//      a {
//        margin-right: 15px;
//        &.btnDeleteUser, &.btnUsersOfGroup, &.btnDeleteGroup, &.btnUsersOfGroup {
//          margin-right: 0;
//          padding: 15px;
//          cursor: pointer;
//          @media (max-width: $screen-xs-max) {
//            padding: 10px;
//          }
//        }
//
//      }
//    }
//  }
//}
//
//.conversation-text-editor {
//  @media (min-width: $screen-lg-min) {
//    padding: 0;
//  }
//  @media (max-width: $screen-xs-max) {
//    padding: 0;
//  }
//}
//
//.menu_readers {
//  position: absolute;
//  background-color: $theme-green;
//  color: $theme-white;
//  top: 18px;
//  list-style: none;
//  padding: 0;
//  li {
//    display: inline-block;
//    padding: 5px;
//    position: relative;
//    white-space: nowrap;
//    &:not(:first-of-type):before {
//      content: ',';
//      position: absolute;
//      left: -2px;
//    }
//  }
//
//}
//
////Slick Override
//.slick-prev:before, .slick-next:before {
//  font-family: 'Font Awesome 5 Pro';
//  font-size: 20px;
//  line-height: 1;
//  opacity: .75;
//  color: $theme-black;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//.slick-slider {
//  padding-top: 15px;
//  padding-bottom: 15px;
//}
//
//.slick-next {
//  @media (min-width: $screen-lg-min) {
//    right: -12px;
//  }
//  @media (max-width: $screen-md-max) {
//    right: 0;
//  }
//  &:before {
//    content: '\f054';
//  }
//}
//
//.slick-prev {
//  @media (min-width: $screen-lg-min) {
//    left: -12px;
//  }
//  @media (max-width: $screen-md-max) {
//    left: 0;
//  }
//  &:before {
//    content: '\f053';
//
//  }
//}

//set Helpfull feedback
.feedback-info {
  background: $theme-white;
  color: $theme-black;
  position: fixed;
  left: 0;
  right: 0;
  min-height: 20px;
  z-index: 1001;
  line-height: 21px;
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  bottom: 0;
  opacity: 1;
  -webkit-transition-duration: 0.8s;

  transition-duration: 0.8s;
}

.feedback-info.loading {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}

//set cookie policy
.cookie-info {
  background: $theme-white;
  color: $color-gray-base;
  position: fixed;
  left: 0px;
  right: 0px;
  min-height: 20px;
  z-index: 2147483647;
  line-height: 21px;
  padding: 10px 0px;
  font-size: 14px;
  text-align: center;
  bottom: 0px;
  //opacity: 1;
  -webkit-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.cookie-info.loading {
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}

// Einverständniserklärung
body.action-user_optin.user_optin {
  #wrapper > .container {
    background: $theme-white;
    max-width: 1078px;
    font-size: 14px;

    h3 {
      font-size: 14pt;
      margin: 10px 0 10px 0;
      font-weight: 500;
    }

    .panel-heading i.widget-headline-icon {
      font-size: 15pt;
      height: 2em;
      margin-right: 1em;
      padding: 0.45em;
      width: 2em;
      position: absolute;
      left: 0;
      top: 0;
    }

    .control-label {
      text-align: left;
    }

    .policy-settings {
      margin-top: 15px;
    }
  }
}

/*delete button*/
.btn-delete {
  border: 0;
  background: transparent !important;
  box-shadow: none;

  i {
    color: $theme-white;
  }
}

/* To top link */
.totop {
  background: $theme-white;
  border: 1px solid $theme-black;
  bottom: 0px;
  position: fixed;
  right: 0px;
  z-index: 104400;
  padding: 5px 8px;
  font-size: 1.6em;

  a {
    color: $theme-black;
  }
}

.front-page-widget-wrapper {
  background-color: white;
}

.widget-content-wrapper {
  border-top: 1px solid $theme-black;
}

.btn.btn-primary {
  background-color: #ffffff;
  color: $theme-black;
  border-color: transparent;
  box-shadow: inset 0 0 0 1px #888, 0 0 1px transparent;
  border-radius: 32px;
  outline: none;
}

.btn.btn-primary:hover, .btn.btn-primary:focus {
  box-shadow: inset 0 0 0 2px #9ad21c, 0 0 1px transparent;
  border-color: transparent;
  outline: none;
}

.dropdown-menu.sub-menu.sights {
  label {
    font-weight: normal;
  }
}

.notice_header, .notice_policy {
  cursor: default;
}

.notice_policy {
  border-top: 1px dotted $theme-black;
}

.notice_policy small {
  margin-right: 10px;
}

.notice_header {
  text-align: center;
}

div#notice_menu {
  height: 25vh;
  width: 25vw;
  margin: 15px;
  border: 1px solid #c1c1c1;
  color: #282828;
  overflow: auto;
  margin-top: 5px;

  &.cke_focus {
    border: 1px solid $header-newsticker-background-color;
  }
}


.magazine-heading {
  border-radius: 0;
  background-color: $elearning-wrapper-header-background-color;
  color: $elearning-wrapper-header-font-color;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 1px solid $elearning-wrapper-header-border-bottom-color;

  i.widget-headline-icon {
    background-color: $elearning-wrapper-header-icon-background-color;
    color: $elearning-wrapper-header-icon-font-color;
    font-size: $elearning-wrapper-header-icon-font-size;
    height: 2em;
    margin-right: 1em;
    padding: 0.45em;
    width: 2em;
  }
}

.additional-user-data-border {
  margin: 0;
}

.additional-user-data-next {
  margin-bottom: 20px;
}

/* FRONT-SLIDER */
.front-slider {
  margin-bottom: 15px;
  padding: 0;
  background-color: $theme-white;
  -webkit-box-shadow: 2px 2px 5px 0 $color-gray8;
  -moz-box-shadow: 2px 2px 5px 0 $color-gray8;
  box-shadow: 2px 2px 5px 0 $color-gray8;

  img {
    width: 100%;
    height: auto;
  }

  span[data-kms="media"] img {
    padding: 0;
  }

  .carousel-control.left,
  .carousel-control.right {
    font-size: 40px;
    background-image: none;
    filter: none;
  }

  span[data-kms="media"] img {
    padding: 0;
  }

  .item {
    background: transparent !important;
  }
}

i.missed-uploads {
  background-color: red;
  position: absolute;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 15px;
  font-size: 10px;
  top: -7px;
  right: 7px;
}

@media screen and (min-width: 992px) {
  #sidebar-left .multilevelpushmenu_wrapper h2 .fa {
    display: none !important;
  }
}

.page-edit-button-group, .content-edit-button-group {
  .dropdown-menu > li > span {
    padding: 3px 20px;
  }
}

.star-rating-control div.star-rating,
.star-rating-control div.star-rating a {
  background: url('/images/theme/star.gif') no-repeat 0 0px
}

.star-rating-control div.star-rating-hover a {
  background-position: 0 -32px;
}

// colorbox zentriert
#cboxLoadedContent {
  display: flex;
  justify-content: center;
}

// Neus Layout
body.site {
  &.page .block.view-html, &.page .block .single-post, &.content #news-content {
    font-size: 12pt !important;

    h1, h2, h3, h4, h5, h6 {
      &:not(.panel-title):not(.list-group-item-heading) {
        margin: 15px 0 !important;
        // color: $theme-black;
      }

    }

    h1:not(.panel-title):not(.list-group-item-heading) {
      font-size: 2.2em;
    }

    h2:not(.panel-title):not(.list-group-item-heading) {
      font-size: 1.9em;
    }

    h3:not(.panel-title):not(.list-group-item-heading) {
      font-size: 1.6em;
    }

    h4:not(.panel-title):not(.list-group-item-heading) {
      font-size: 1.3em;
    }

    h5:not(.panel-title):not(.list-group-item-heading) {
      font-size: 1.2em;
    }

    h6:not(.panel-title):not(.list-group-item-heading) {
      font-size: 1.0em;
    }

    ul, ol {
      > li {
        > ol, > ul {
          margin-bottom: 0 !important;
        }
      }
    }

    a {
      color: $link-text-color;

      &:hover, &:active {
        color: $link-hover-color;
      }
    }

    // Hinweisboxen / alert
    .alert {
      border-radius: $alert-border-radius;

      &.alert-primary, &.alert-default {
        color: $bs-primary-color;
        background-color: $bs-primary-bg;
        border-color: $bs-primary-color;
      }

      &.alert-success {
        color: $bs-success-color;
        background-color: $bs-success-bg;
      }

      &.alert-info {
        color: $bs-info-color;
        background-color: $bs-info-bg;
      }

      &.alert-warning {
        color: $bs-warning-color;
        background: $bs-warning-bg;
        border: none;
      }

      &.alert-danger {
        color: $bs-danger-color;
        background: $bs-danger-bg;
      }
    }

    // panel
    .panel {
      border: none;

      &.panel-primary, &.panel-default, &.panel-success, &.panel-info, &.panel-warning, &.panel-danger {
        .panel-heading {
          padding: $panel-heading-padding !important;
          border-radius: $panel-heading-border-radius !important;
          border: none;
        }
      }

      &.panel-primary, &.panel-default {
        > .panel-heading {
          border: solid 1px $bs-default-color !important;
          background: $bs-default-bg;
          color: $bs-default-color;
        }

      }

      &.panel-success {
        .panel-heading {
          color: $bs-success-color;
          background-color: $bs-success-bg;
        }
      }

      &.panel-info {
        .panel-heading {
          color: $bs-info-color;
          background-color: $bs-info-bg;
        }
      }

      &.panel-warning {
        .panel-heading {
          color: $bs-warning-color;
          background: $bs-warning-bg;
        }
      }

      &.panel-danger {
        .panel-heading {
          color: $bs-danger-color;
          background: $bs-danger-bg;
        }
      }
    }

    // Panel Group Collapse
    .panel-group {
      > .panel {
        border: none;

        &.panel-primary, &.panel-default, &.panel-success, &.panel-info, &.panel-warning, &.panel-danger {
          > .panel-heading {
            // padding: 10px $panel-heading-padding !important;
            padding: 0px !important;
            //border-radius: $panel-heading-border-radius !important;
            .panel-title {
              font-family: gilroy-bold, sans-serif;

              a {
                display: block;
                position: relative;
                padding: 10px 15px;

                &:hover {
                  color: inherit;

                }

                &:before {
                  content: "\f078";
                  display: inline-block;
                  font: normal normal normal 14px/1 FontAwesome;
                  font-family: "Font Awesome 5 Pro";
                  font-size: inherit;
                  text-rendering: auto;
                  -webkit-font-smoothing: antialiased;
                  position: absolute;
                  right: 25px;
                  top: 14px;
                }
              }

              a[aria-expanded="true"] {
                &:before {
                  @include transform(rotate(180deg));
                }
              }
            }
          }

          .panel-collapse {
            .panel-body {
              border: none !important;
            }
          }
        }

        &.panel-primary, &.panel-default {
          > .panel-heading {
            background: $bs-default-bg;
            color: $bs-default-color;
            border: none !important;

            a {
              border-radius: $panel-heading-border-radius !important;
              box-shadow: inset 0 0 0 1px $color-gray9, 0 0 1px transparent;

              &:hover {
                border-color: transparent !important;
                box-shadow: inset 0 0 0 2px #9ad21c, 0 0 1px transparent;
                border-color: $pystachio-color !important;
              }
            }

            .panel-title {
            }
          }


        }

        &.panel-success {
          .panel-heading {
            color: $bs-success-color;
            background-color: $bs-success-bg;

            a {
              border-radius: 25px;
              color: $bs-success-color;

              &:hover {
                background: darken($bs-success-bg, 10%);
              }
            }
          }
        }

        &.panel-info {
          .panel-heading {
            color: $bs-info-color;
            background-color: $bs-info-bg;

            a {
              border-radius: 25px;
              color: $bs-info-color;

              &:hover {
                background: darken($bs-info-bg, 10%);
              }
            }
          }
        }

        &.panel-warning {
          .panel-heading {
            color: $bs-warning-color;
            background: $bs-warning-bg;

            a {
              border-radius: 25px;
              color: $bs-warning-color;

              &:hover {
                background: darken($bs-warning-bg, 10%);
              }
            }
          }
        }

        &.panel-danger {
          .panel-heading {
            color: $bs-danger-color;
            background: $bs-danger-bg;

            a {
              border-radius: 25px;
              color: $bs-danger-color;

              &:hover {
                background: darken($bs-danger-bg, 10%);
              }
            }
          }
        }
      }
    }

    // List Group
    .list-group {
      // padding: 0 15px !important;
      .list-group-item {
        margin: 5px 0;
        border-color: $bs-primary-color;

        &:first-child {
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
        }

        &:last-child {
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }

        .list-group-item-heading {

        }

        .badge {
          background: $bs-primary-color;
          color: $theme-white;
        }

        .list-group-item-text {
          color: $theme-black;
        }

        &.list-group-item-primary, &.list-group-item-default {
          .list-group-item-heading {
            color: $bs-primary-bg;
          }

          .badge {
            background: $bs-primary-color;
            color: $theme-white;
          }

          color: $bs-primary-color;
          background: $theme-white;
          border-color: $bs-primary-bg;
        }

        &.list-group-item-info {
          .list-group-item-heading {
            color: $bs-info-bg;
          }

          .badge {
            background: $bs-info-bg;
            color: $theme-black;
          }

          color: $bs-info-color;
          background: $theme-white;
          border-color: $bs-info-bg;
        }

        &.list-group-item-success {
          .list-group-item-heading {
            color: $bs-success-bg;
          }

          .badge {
            background: $bs-success-bg;
            color: $theme-black;
          }

          color: $bs-success-color;
          background: $theme-white;
          border-color: $bs-success-bg;
        }

        &.list-group-item-warning {
          .list-group-item-heading {
            color: $bs-warning-color;
          }

          .badge {
            background: $bs-warning-bg;
            color: $theme-black;
          }

          color: $bs-warning-color;
          background: $theme-white;
          border-color: $bs-warning-bg;
          border-width: 2px;
        }

        &.list-group-item-danger {
          .list-group-item-heading {
            color: $bs-danger-bg;
          }

          .badge {
            background: $bs-danger-bg;
            color: $theme-white;
          }

          color: $bs-danger-color;
          background: $theme-white;
          border-color: $bs-danger-bg;
        }
      }

    }

    // Buttons
    .btn {
      border-radius: 25px;
      margin: 2px 5px;

      &.btn-primary, .btn-default {

      }

      &.btn-success {
        color: $bs-success-color;
        background-color: $bs-success-bg;
        border: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;

        &:hover {
          background: darken($bs-success-bg, 10%);
        }

      }

      &.btn-info {
        color: $bs-info-color;
        background-color: $bs-info-bg;
        border: none !important;

        &:hover {
          background: darken($bs-info-bg, 10%);
        }
      }

      &.btn-warning {
        color: $bs-warning-color;
        background: $bs-warning-bg;
        border: none !important;

        &:hover {
          background: darken($bs-warning-bg, 10%);
        }
      }

      &.btn-danger {
        color: $bs-danger-color;
        background: $bs-danger-bg;
        border: none !important;

        &:hover {
          background: darken($bs-danger-bg, 10%);
        }
      }
    }
  }
}

.workflow-branch {
  color: $color-danger !important;
}

.workflow-action-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
  align-items: center;
}

// Impressum-Modal klickbar
.action-login {
  .modal-backdrop {
    z-index: -1;
  }
}