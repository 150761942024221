/* -------------- PAGE COMPARE -------------- */

#page-compare-modal {
  z-index: 99;
  border: 1px solid $theme-green;
  min-height: 60px;
  border-bottom: none;
  position: sticky;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
  -webkit-box-shadow: 3px -1px 10px 1px #c5c5c5;
  -moz-box-shadow: 3px -1px 10px 1px #c5c5c5;
  box-shadow: 3px -1px 10px 1px #c5c5c5;
  overflow: hidden;

  .page-compare-list {
    flex: 1 1 auto;
    max-width: 65%;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    .page-compare-page-title {
      font-size: 16px;
      display: flex;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }

      i {
        color: $color-danger;
        cursor: pointer;
      }
    }
  }

  .page-compare-action {
    padding: 15px;
    font-size: 20px;
    flex: 0 0 auto;
  }
}

@media (min-width: 1200px) {
  #page-compare-modal {
    width: 25%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #page-compare-modal {
    width: 30%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #page-compare-modal {
    width: 50%;
  }
}

@media (max-width: 767px) {
  #page-compare-modal {
    width: 75%;
  }
}

@media (max-width: 480px) {
  #page-compare-modal {
    width: 90%;
  }
}

.action-compare_page, .action-compare_page_workflow, .action-compare_content, .action-compare_content_workflow {
  .content-dynamic {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;

    .page-compare-empty, .content-compare-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      > i {
        font-size: 7vw;
        color: $color-danger;
      }

      > p {
        font-size: 1.4vw;
        color: $color-gray11;
        text-align: center;
      }
    }

    .page-compare-item, .content-compare-item {
      flex: 1;
      border-right: dotted black 1px;
      border-bottom: double $color-danger 2px;
      margin-right: 5px;
      padding-right: 5px;
      padding-left: 5px;
      padding-bottom: 15px;
      -webkit-transition: background-color 1000ms linear;
      -ms-transition: background-color 1000ms linear;
      transition: background-color 1000ms linear;

      &:last-child {
        border-right: none;
        margin-right: 0;
      }

      &.loading {
        background-color: $color-danger;
      }

      > div > i {
        float: right;
        cursor: pointer;
      }

      iframe {
        overflow-y: scroll;
        border: none;
      }

      .page-compare-item, .content-compare-item, .content-compare-item-title, .page-compare-item-title {
        color: $theme-green;
      }

      .page-compare-remove {
        color: $color-danger;
        font-size: 18px;
      }
    }
  }
}