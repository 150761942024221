@import "_global_includes.scss";

.icon-bar {
  background-color: $theme-blue;
}

#wrapper {
  position: relative;
}

#sidebar-right {
  .content-news-teaser {
    overflow: hidden;
  }
}

@media (max-width: $screen-md-max) {
}

@media (max-width: $screen-sm-max) {
  header {
    .project-title {
      .col-xs-12 {
        padding: 0 0 0 15px;
      }
    }

    .menu-user {
      min-height: 0 !important;
      margin-top: 0 !important;
    }

    .wrapper-user-menu {
      position: relative;
      padding-right: 0;

      .navbar {
        position: static;
        margin-bottom: 0;

        > li {
          display: inline-block;

          a {
            padding-right: 10px;
          }
        }
      }

      > ul > .dropdown {
        position: static;

        > .dropdown-menu {
          left: 0;
          right: 0;
          width: 100%;
          margin: 0;
          padding: 0;
          position: absolute !important;
          z-index: 1011;
        }

        ul > .dropdown {
          position: relative !important;
        }

      }

      ul.menu-user.nav li .dropdown-menu {
        background-color: $theme-white !important;
        width: 100%;
        right: 0;
        border: 0;
        box-shadow: none;

        &:after {
          display: none;
          visibility: hidden;
        }
      }

    }
  }
  .wrapper-search-global {
    transition: padding-left linear 0.1s;
    -moz-transition: padding-left linear 0.1s;
    -o-transition: padding-left linear 0.1s;
    -webkit-transition: padding-left linear 0.1s;
    width: 100%;

  }
  .btn-main-menu {
    display: none;
  }
  .levelHolderClass {
    z-index: 1000;
  }
  #sidebar-left {
    position: absolute;
    top: -57px;
    left: 0;
  }
  #sidebar-left .multilevelpushmenu_wrapper h2 {
    padding-top: 7px !important;
    min-height: 34px !important;
    padding-bottom: 0 !important;
    //border-top: 1px solid;
    border-bottom: 0px solid;
    height: 100%;
  }
}

@media (max-width: $screen-sm-max) {
  .panel-heading {
    h2 {
      height: auto;
    }

    .panel-title {
      .accordion-count {
        height: auto;
        padding: 12px 11px;
      }

      .accordion-title {
        font-size: 11pt;
      }
    }
  }
}

.wrapper-btn-main-menu {
  display: none;
}

.isVisible {
  ul.dropdown-menu {
    display: block !important;
    @media (max-width: $screen-sm-max) {
      position: static !important;
    }
  }
}

.pager {
  margin-bottom: 20px !important;
}

.hideItem {
  visibility: hidden !important;
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  #sidebar-right {
    padding: 0;
  }
  #page-content-wrapper {
    .container-fluid {
      //padding: 0;
    }
  }
}

img[src=""] {
  display: none;
}

.header-news-wrapper {
  img[src=""] {
    display: block;
    background-image: url(../images/dummy.svg);
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
}

//This will add padding-left to list dropdown having image inside
.has-image {
  padding-left: 40px !important;
}

//image center content
.image-center {
  @media (min-width: $screen-sm-min) {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
    }
    i {
      font-size: 100px !important;
      position: absolute;
      margin: auto;
      text-align: center;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  @media (min-width: $screen-md-min) {
    img {
      width: 350px;
    }
    i {
      font-size: 800px;
    }
  }
  @media (min-width: $screen-sm-max) {
    img {
      width: 350px;
    }
    i {
      font-size: 800px;
    }
  }

}

//Check List Style: With Highlight
.with-checklist {
  h1 {
    color: $theme-blue;
  }

  ul {
    list-style: none !important;
    padding: 0;

    li {
      position: relative;
      padding-left: 30px;
      margin-bottom: 10px;
      font-size: 12pt;

      &:before {
        position: absolute;
        left: 0;
        top: 4px;
        content: "\f00c";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}

.chapter-text {
  float: none;
  width: auto;
}

.chapter-icon {
  float: left;
  text-align: left;
  width: 70px;
  padding-right: 0;
}