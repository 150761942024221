@import "_global_includes.scss";

/* sntNachrichtensystem */
//Conversation
$chat-primary-color: #ffffff;
$chat-secondary-color: #f2f2f2;
$chat-border-color: #b3b3b3;
$chat-icon-color: #ffffff;
$chat-window-background: #ffffff;
$chat-user-background-color: $color-gray3;
$chat-admin-background-color: $color-gray1;
$chat-dropdown-background: #359138;
$chat-dropdown-font-color: #262626;
$chat-dropdown-icon-color: #262626;
$chat-dropdown-custom-background: transparent;
$chat-notice-highlight: $theme-red;
$chat-btn-load-background: $theme-green;
$chat-dropdown-background: $theme-green;
$chat-admin-border-color: $theme-green;
$chat-menu-readers-background: $theme-red;
$chat-menu-readers-font-color: $theme-white;
$chat-serach-result-background: $color-gray11;
$chat-slick-slider-heading-color: $theme-black;
$screen-sm-min: 768px;
$screen-xs-max: 767px;
$screen-lg-min: 992px;
$screen-md-max: 1199px;

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

header .menu-user li .dropdown-menu {
  &.conversation {
    @media (min-width: $screen-sm-min) {
      width: 250px;
    }
    > li {
      border-bottom: 0;
      &.all {
        text-align: left;
        padding: 0 10px 0 10px;
        border-bottom: 1px solid $chat-border-color;
        &:last-of-type {
          border-bottom: 0;
        }
      }
      a {
        padding: 7px 20px;
        font-weight: 700;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        .message {
          font-weight: 300;
          margin: 5px 0;
          white-space: normal;
          p {
            margin-bottom: 0;
          }
          .image {
            float: left;
            width: 25%;
            img {
              height: auto;
              width: 35px;
            }
          }
          .message-text {
            float: right;
            width: 75%;
            .text {
              font-style: italic;
            }
            .name {
              font-size: 10pt;
              font-weight: 700;
            }
            .time {
              font-size: 8pt;
            }
          }
        }
      }
    }
  }
}

.conversation {
  > li {
    border-bottom: 0;
  }
}

.conversation-block, .conversation-history-block {
  .panel-body {
    min-height: 0;
  }
  .panel-title {
    border-bottom: 0 solid $chat-border-color;
    font-size: 1.7em;
  }
}

.dropdown-menu {
  a {
    cursor: pointer;
  }
}

.conversation-history {
  display: inline-block;
  margin-right: 15px;
  padding: 10px 0;
  i {
    margin-right: 5px;
    font-size: 1.3em;
  }
  .title {
    font-size: 1.3em;
  }
  .date-create {
    display: none;
    margin-left: 5px;
  }
}

.conversation-item-wrapper {
  margin-bottom: 50px;

}

.conversation-history-select {
  padding: 15px 60px 100px;
  @media (max-width: $screen-xs-max) {
    padding: 0;
  }
  .form-horizontal {
    margin-bottom: 15px;
  }

}

.conversation-history-wrapper {
  position: relative;
  padding: 15px 60px 0;
  border-top: 1px solid $chat-border-color;
  min-height: 80px;
  @media (max-width: $screen-xs-max) {
    padding: 10px 0;
  }
  .navbar-right {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    .dropdown-toggle {
      background-color: $chat-dropdown-custom-background;
      height: 1.5em;
      width: 2em;
      font-size: 16pt;
      padding: 0;
      vertical-align: middle;
      text-align: center;
      line-height: 1.5em;
      i {
        color: $chat-dropdown-font-color;
      }
      &:hover, &:focus {
        background-color: $chat-dropdown-custom-background;
        i {
          color: $chat-dropdown-font-color;
        }
      }
    }
    .open {
      .dropdown-toggle {
        display: inline-block;
        float: right;
      }
      .sub-menu.conversation {
        background-color: $chat-window-background;
        z-index: 10;

        @media (max-width: $screen-xs-max) {
          margin-top: 32px;
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        }

      }
    }
  }
  .member {
    i {
      font-size: 2.5em;
    }
  }
  .conversation-person-info {
    .name {
      font-weight: 700;
    }
    .designation {
      font-style: italic
    }
    .text {
      font-size: 8pt;
    }
  }
}

.selection-title {
  border-bottom: 1px solid $chat-border-color;
  font-weight: 700;
}

.notice-highlight {
  padding: 10px 0 0;
  color: $chat-notice-highlight;
  i {
    font-size: 9pt;
    color: $chat-notice-highlight;
  }
  .time-period, b {
    font-size: 9pt;
  }
}

.conversation-btn-load-prev {
  height: 15px;
  background-color: $chat-btn-load-background;
  color: $theme-white;
  cursor: pointer;
}

.conversation-btn-load-prev:hover i {
  -webkit-animation: jump 1.5s ease 0s infinite normal;
  animation: jump 1.5s ease 0s infinite normal;
}

@-webkit-keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
  80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-3px);
  }
  50% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-1px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.conversation-group {
  padding: 10px 20px;
  border-top: 1px solid $chat-border-color;
  position: relative;
  .date-created {
    display: block;
    font-size: 8pt;

    @media (max-width: $screen-xs-max) {
      margin-right: 100px;
    }

  }
  h3 {
    margin-top: 0;
  }
  .slick-slider-heading {
    display: none;
  }
  .navbar-right {
    margin-right: 0;
    background-color: $chat-primary-color;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    .open {
      .dropdown-toggle {
        display: inline-block;
        float: right;
      }
      .sub-menu.conversation {
        background-color: $chat-window-background;
        @media (max-width: $screen-xs-max) {
          margin-top: 32px;
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        }

      }
    }

    .dropdown-toggle {
      height: 1.5em;
      width: 2em;
      font-size: 16pt;
      padding: 0;
      vertical-align: middle;
      text-align: center;
      line-height: 1.5em;
      background-color: $chat-dropdown-background;
      i {
        color: $chat-icon-color;
      }
      &:hover, &:focus {
        background-color: $chat-dropdown-background;
        i {
          color: $chat-icon-color;
        }
      }
    }
  }
  #MembersOfConversation {
    .user-details {
      display: inline-block;
      margin-bottom: 15px;
      text-align: center;
      &.admin {
        img {
          border: 2px solid $chat-admin-border-color;
        }

      }
      a {
        display: block;
        margin-bottom: 10px;
        img {
          margin: auto;
        }
      }
      .user-name {
        display: block;
        word-wrap: break-word;
        margin-top: 5px;
        text-align: center;

      }
      .department-icon {
        height: 28px;
      }
    }
  }
}

.unread-messsages {
  margin-bottom: 5px;
  .message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.conversation-member-profile-preview {
  i {
    font-size: 2.5em;
  }
}

.conversation-window {

  padding: 0 30px 30px;
  @media (max-width: $screen-xs-max) {
    margin-left: -15px;
    margin-right: -15px;
  }
  #somediv_top {
    margin-top: 15px;
  }
  #MembersOfConversation {
    @media (min-width: $screen-lg-min) {
      width: 300px;
      float: right;
      margin-bottom: 30px;
      padding-right: 0;
      clear: both;
    }

    margin: 0 auto;
    @include clearfix;

    .user-department {
      display: none;
    }
    .user-details {
      text-align: center;
      img {
        margin: auto;
      }

      &:first-of-type {
        margin-left: 0;
      }
      .user-name {
        display: block;
        margin-top: 5px;
        word-wrap: break-word;
        text-align: center;

      }
      .department-icon {
        font-size: 2.5em;
      }
    }
  }
  #btnPrevMessages {
    position: relative;
    .previous-messages {
      position: absolute;
      top: 0;
      left: -25px;
      z-index: 10;
      @media (max-width: $screen-xs-max) {
        top: -25px;
        left: -13px;
      }
    }
  }
  .btn-load {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    background: transparent;
    border: 0;
  }
  .conversation-detail {
    margin-bottom: 40px;
    @media (max-width: $screen-xs-max) {
      font-size: 10pt;
    }
    .conversation-text {
      padding: 15px;
      position: relative;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      border-radius: 15px;
    }
    .user-image {
      img {
        width: 35px;
        height: auto;
      }
      .user-name {
        display: block;
        margin-top: 5px;
        word-wrap: break-word;
        font-weight: 700;
        @media (max-width: $screen-xs-max) {
          display: none;
        }
        & + div {
          font-size: 9pt;
        }
      }
    }
    .last-seen {
      position: absolute;

      a {
        text-decoration: none;
      }
      button {
        background: transparent;
        outline: 0;
        border: 0;
      }
    }
    &.left {
      float: left;
      @media (max-width: $screen-xs-max) {
        padding-left: 0;
      }
      .last-seen {
        right: -5px;
      }

      .conversation-text {
        background-color: $chat-admin-background-color;
        &:before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 12px 12px 12px 0;
          border-color: transparent $chat-admin-background-color;
          display: block;
          width: 0;
          left: -12px;
          top: 0;
          bottom: 0;
          height: 5px;
          margin: auto;
        }

      }
    }
    &.right {
      float: right;

      @media (max-width: $screen-xs-max) {
        padding-right: 0;
      }
      .last-seen {
        right: -5px;
      }
      .user-image {
        float: right;
        text-align: right;
      }
      .conversation-text {
        background-color: $chat-user-background-color;
        &:before {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 12px 0 12px 12px;
          border-color: transparent $chat-user-background-color;
          display: block;
          width: 0;
          right: -12px;
          top: 0;
          bottom: 0;
          height: 5px;
          margin: auto;
        }
      }
    }
    .last-seen {
      position: absolute;
      right: -5px;
      a {
        text-decoration: none;
      }
      button {
        background: transparent;
        outline: 0;
        border: 0;
      }
    }
    .date {
      text-align: right;
      font-size: 8pt;
      margin-bottom: -8px;
    }
  }
  .send-button {
    float: right;
    margin-top: 30px;
  }
  .ConversationMessage {
    clear: both;
  }
}

.group-header-text {
  font-size: 10pt;
  display: block;
  text-align: left;
  margin: 15px 0 10px 0;
}

.group-selection-headiline, .person-selection-headiline {
  font-weight: 700;
  margin: 15px 0 0;
  @media (max-width: $screen-xs-max) {
    font-size: 10pt;
  }
}

.group-selection-headiline {
  margin: 15px 0 0 0;
}

.buttons-group {
  .btn {
    margin: 20px 0;
  }
}

.table-responsive {
  border: 0;
}

#myUserTable, #myGroupTable {
  width: 100%;
  @media (max-width: $screen-xs-max) {
    font-size: 10pt;
  }
  tr {
    background: transparent;
    td {
      padding-bottom: 10px;
      color: #000000 !important;
      position: relative;
      .department-icon {
        font-size: 15pt;
      }

      a {
        margin-right: 15px;
        &.btnDeleteUser, &.btnUsersOfGroup, &.btnDeleteGroup, &.btnUsersOfGroup {
          margin-right: 0;
          padding: 15px;
          cursor: pointer;
          @media (max-width: $screen-xs-max) {
            padding: 10px;
          }
        }
        .conversation-member-profile-preview {
          i {
            font-size: 15pt;
          }

        }
      }
    }
  }
  .navbar-right {
    margin-right: 0;

    background-color: $chat-primary-color;
    position: absolute;
    right: 0;
    top: 5px;
    margin: 0;

    .open {
      z-index: 1000;

      .dropdown-toggle {
        display: inline-block;
        float: right;
      }
      .sub-menu.conversation {
        background-color: $chat-window-background;
        @media (max-width: $screen-xs-max) {
          margin-top: 32px;
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        }
      }
    }

    .dropdown-toggle {
      height: 1.5em;
      width: 2em;
      font-size: 12pt;
      padding: 0;
      vertical-align: middle;
      text-align: center;
      line-height: 1.5em;
      background-color: $chat-dropdown-custom-background;
      margin-right: 0;
      i {
        color: $chat-dropdown-icon-color;
      }
      &:hover, &:focus {
        background-color: $chat-dropdown-custom-background;
        i {
          color: $chat-dropdown-icon-color;
        }
      }
    }
  }
  .dropdown-menu > li {
    // margin-left: 15px;
    > p {
      padding: 0;
    }
    a {
      font-size: 14px;
      position: relative;
      padding-left: 40px;
      margin-right: 0;
      .fa {
        position: absolute;
        left: 15px;
        padding-top: 3px;
      }

    }
  }
}

.conversation-text-editor {
  @media (min-width: $screen-lg-min) {
    padding: 0;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0;
  }
}

.menu_readers {
  position: absolute;
  background-color: $chat-menu-readers-background;
  color: $chat-menu-readers-font-color;
  top: 18px;
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
    padding: 5px;
    position: relative;
    white-space: nowrap;
    &:not(:first-of-type):before {
      content: ',';
      position: absolute;
      left: -2px;
    }
  }

}

//Serach-rEsult
#conversation-live-search-result {
  margin-top: -15px;
  .teilnehmer-search-results {
    background-color: $chat-serach-result-background;
    width: 100%;
    outline: 0 !important;
    border: 1px solid $border-color;
    margin-top: -4px;
    .conversation-member-profile-preview {
      float: left;
      width: 15%;
      padding: 10px;
      text-align: center;
    }
    .contact-details {
      width: 85%;
      float: right;
      padding: 10px;
      text-align: left;
    }

  }
}

//Slick Override
.slick-prev:before, .slick-next:before {
  font-family: 'Font Awesome 5 Pro';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  padding: 15px;
}

.slick-slider-heading {
  float: right;
  width: 300px;
  text-align: center;
  font-size: 12pt;
  color: $chat-slick-slider-heading-color;
  font-style: italic;
}

.slick-slide {
  outline: 0 !important;
}

.slick-next {
  @media (min-width: $screen-lg-min) {
    right: -12px;
  }
  @media (max-width: $screen-md-max) {
    right: 0;
  }
  &:before {
    content: '\f054';
  }
}

.slick-prev {
  @media (min-width: $screen-lg-min) {
    left: -12px;
  }
  @media (max-width: $screen-md-max) {
    left: 0;
  }
  &:before {
    content: '\f053';

  }
}