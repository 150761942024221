@import "../_global_includes.scss";

/* ------------ Header Dropdown Menu for Sights ------------ */

header .menu-user .dropdown-menu.sub-menu.sights {

  background: $sight_header_menu_background;
  color: $sight_header_menu_text_color;

  > li {
    border-bottom: 1px solid $sight_header_menu_category_separator_color;
    padding: 20px;

    &:hover {
      background: $sight_header_menu_background;
    }

    &:last-child {
      border-bottom: none;
    }

    .header-menu-sight-item {
      display: flex;

      .header-menu-sight-item-category-image {
        max-width: 50px;
        margin-right: 10px;
        min-width: 50px;
        text-align: right;
      }

      .header-menu-sight-item-sights {

        .header-menu-sight-item-sights-wrapper {
          display: grid;
          grid-template-columns: 1px auto;
          grid-template-rows: auto;
          margin-left: 5px;
          margin-top: 10px;

          .header-menu-sight-item-sights-tree {
            background: $sight_header_menu_tree_background;
            border-bottom: 10px solid $sight_header_menu_background;
            height: 100%;
            width: 1px;
          }
        }

        ul {
          padding: 0;

          li.header-menu-sight-item-category {
            .header-menu-sight-item-sight-name {
              font-weight: bold;
              min-width: 140px;
            }
          }

          li {
            border: none;
            display: flex;
            margin-top: 10px;
            list-style-type: none;
            padding: 0;

            &:first-child {
              margin-top: 0;
            }

            .header-menu-sight-item-dash {
              background: $sight_header_menu_tree_background;
              height: 1px;
              margin-right: 3px;
              position: relative;
              top: 10px;
              width: 9px;
            }

            .header-menu-sight-item-sight-name {
              font-weight: normal;
              margin-right: 10px;
              min-width: 122px;
              text-align: left;

              i {
                padding-left: 5px;
              }
            }

            .header-menu-sight-item-category-sights-checkbox {
              $sight_checkbox_size: 12px;

              /* Customize the label (the container) */
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                width: $sight_checkbox_size;
                height: $sight_checkbox_size;

                /* On mouse-over, add a background color */
                &:hover input ~ .checkmark {
                  //background-color: #ccc;
                }

                /* Hide the browser's default checkbox */
                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  z-index: 1;
                  width: $sight_checkbox_size;
                  height: $sight_checkbox_size;
                  margin: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: $sight_checkbox_size;
                  width: $sight_checkbox_size;
                  background-color: $sight_header_menu_checkbox_background;
                  border: 1px solid $sight_header_menu_checkbox_border_color;
                  border-radius: 4px;
                }

                /* When the checkbox is checked, add a background */
                input:checked ~ .checkmark {
                  background-color: $sight_header_menu_checkbox_check_background;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                }

                /* Show the checkmark when checked */
                input:checked ~ .checkmark:after {
                  display: block;
                }

                /* Style the checkmark/indicator */
                .checkmark:after {
                  left: 3px;
                  top: 0.5px;
                  width: 4px;
                  height: 8px;
                  border: solid $sight_header_menu_checkbox_border_color;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
            }
          }
        }
      }
    }
  }

  label {
    font-weight: normal;
  }

  &.single-category {
    .header-menu-sight-item-sights-tree {
      visibility: hidden;
    }
    .header-menu-sight-item-dash {
      visibility: hidden;
    }
  }
}